import Card1 from './Card1';
import FlexBox from './FlexBox';
import { Avatar, Button, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalState';
import { routerUrls } from 'constants';
import { strings } from 'localisation';
import axios from 'axios';
import createGAevent from 'helpers';
import fs from 'fs';

const initialValues = {
  firstName: '',
  surname: '',
  phone: '',
  email: '',
  country: 'Bulgaria',
  streetAddress: '',
  city: '',
  county: '',
  postcode: '',
  note: ''
};

const CheckoutForm2 = ({ paymentOptionSelected, subtotalOrderPrice, totalOrderPrice }) => {
  const { cartList, shippingOption, lang } = useContext(GlobalContext);

  const storeOrderData = (values) => {
    axios.post(`/makeOrder`, { values }).then(
      (response) => {},
      (error) => {
        let errorMessage = {
          error:
            '--------------------------------------------------------------------------' +
            new Date() +
            '-  Error while trying to storeOrderData!!!',
          error_log: error
        };
        fs.appendFile(
          'error_logs.json',
          JSON.stringify(errorMessage) +
            '--------------------------------------------------------------------------------------------------------------'
        );
      }
    );
  };

  let navigate = useNavigate();
  const routeChange = (values) => {
    // let path = 'order-placed';  -> this goes to /checkout/order-placed
    // let path = '/order-placed';  -> this goes to /order-placed
    let path = routerUrls.orderPlaced;
    navigate(path, {
      state: {
        values
      }
    });
  };

  const handleFormSubmit = async (values) => {
    let orderId = new Date()
      .toISOString()
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .toString();
    orderId = orderId.substring(2, orderId.length - 2);
    values['paymentType'] = paymentOptionSelected;
    values['shippingType'] = shippingOption.name;
    values['shippingPrice'] = shippingOption.price;
    values['cartList'] = cartList;
    values['subtotal'] = subtotalOrderPrice.toFixed(2);
    values['total'] = totalOrderPrice.toFixed(2);
    values['uuidOrder'] = orderId;
    storeOrderData(values);
    routeChange(values);
    createGAevent(
      'CheckoutForm',
      'ORDER_PLACED_EVENT',
      'orderId-' + values['uuidOrder'] + '---orderTotal-' + totalOrderPrice.toFixed(2)
    );
    createGAevent(
      'CheckoutForm',
      `ORDER_PLACED-orderId-${orderId}-total-${totalOrderPrice.toFixed(2)}`,
      'orderId-' + values['uuidOrder'] + '---orderTotal-' + totalOrderPrice.toFixed(2)
    );
  };

  const checkoutSchema = yup.object().shape({
    firstName: yup.string().required(strings[lang].requiredField),
    surname: yup.string().required(strings[lang].requiredField),
    phone: yup.number().required(strings[lang].requiredField),
    email: yup.string().required(strings[lang].requiredField),
    country: yup.string().required(strings[lang].requiredField),
    streetAddress: yup.string().required(strings[lang].requiredField),
    city: yup.string().required(strings[lang].requiredField),
    county: yup.string().required(strings[lang].requiredField)
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
      onSubmit={handleFormSubmit}>
      {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Card1
            sx={{
              mb: '1.5rem'
            }}>
            <FlexBox alignItems="center" mb={3.5}>
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  height: 32,
                  width: 32,
                  color: 'primary.text',
                  mr: '0.875rem'
                }}>
                1
              </Avatar>
              <Typography fontSize="20px">{strings[lang].personalDetails}</Typography>
            </FlexBox>

            <Box mb={3.5}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    type="text"
                    name="firstName"
                    label={strings[lang].firstName}
                    error={!!touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    fullWidth
                    onChange={handleChange}></TextField>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    type="text"
                    name="surname"
                    label={strings[lang].surname}
                    error={!!touched.surname && !!errors.surname}
                    helperText={touched.surname && errors.surname}
                    fullWidth
                    onChange={handleChange}></TextField>
                </Grid>
              </Grid>
              <TextField
                sx={{ mt: 1 }}
                type="text"
                name="phone"
                label={strings[lang].phoneNumber}
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                fullWidth
                onChange={handleChange}></TextField>
              <TextField
                sx={{ mt: 1 }}
                type="text"
                name="email"
                label={strings[lang].email}
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                fullWidth
                onChange={handleChange}></TextField>
            </Box>
          </Card1>

          <Card1
            sx={{
              mb: '1.5rem'
            }}>
            <FlexBox mb={3.5} alignItems="center" justifyContent="space-between">
              <FlexBox alignItems="center">
                <Avatar
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'primary.text',
                    mr: '0.875rem',
                    height: 32,
                    width: 32
                  }}>
                  2
                </Avatar>
                <Typography fontSize="20px">{strings[lang].deliveryAddress}</Typography>
              </FlexBox>
            </FlexBox>

            <Box container spacing={3}>
              <Typography>{strings[lang].deliveryNote}</Typography>
              <TextField
                sx={{ mt: 1 }}
                type="text"
                name="streetAddress"
                multiline
                rows={2}
                label={strings[lang].streetAddress}
                error={!!touched.streetAddress && !!errors.streetAddress}
                helperText={touched.streetAddress && errors.streetAddress}
                fullWidth
                onChange={handleChange}></TextField>
              <TextField
                sx={{ mt: 1 }}
                type="text"
                name="city"
                label={strings[lang].city}
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                fullWidth
                onChange={handleChange}></TextField>
              <TextField
                sx={{ mt: 1 }}
                type="text"
                name="county"
                label={strings[lang].county}
                error={!!touched.county && !!errors.county}
                helperText={touched.county && errors.county}
                fullWidth
                onChange={handleChange}></TextField>
              <TextField
                sx={{ mt: 1 }}
                type="text"
                name="country"
                label={strings[lang].country}
                error={!!touched.country && !!errors.country}
                helperText={touched.country && errors.country}
                fullWidth
                defaultValue={strings[lang].bulgaria}
                onChange={handleChange}></TextField>
              <TextField
                sx={{ mt: 1 }}
                style={{ height: 150 }}
                multiline
                rows={4}
                type="text"
                name="note"
                label={strings[lang].note}
                // error={!!touched.note && !!errors.note}
                helperText={touched.country && errors.note}
                fullWidth
                onChange={handleChange}></TextField>
            </Box>
          </Card1>

          <Typography>{strings[lang].personalDetailsConfirmTnC}</Typography>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{
              mt: 1,
              mb: 5
            }}>
            {strings[lang].placeOrder}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default CheckoutForm2;

import React, { useEffect, useState, lazy, Suspense } from 'react';
import './App.css';
import { GlobalProvider } from './context/GlobalState';
import { Routes, Route } from 'react-router-dom';
import { routerUrls, serverUrl } from 'constants';
import Products from './components/Products';
import Home from './components/Home';
import ProductView from './components/ProductView';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import OrderPlaced from './components/pages/OrderPlaced';
import SubmittedForm from 'components/pages/SubmittedForm';
import Layout from './components/Layout';
import FooterBazar from './components/FooterBazar';
import ProductsSearch from 'components/ProductsSearch';
import ProductsCategories from 'components/ProductsCategories';
import PageNotFound from 'components/PageNotFound';
import axios from 'axios';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

// Footer pages
const TermsAndConditions = lazy(() => import('./components/pages/TermsAndConditions'));
const ContactForm = lazy(() => import('./components/pages/ContactForm'));
const UsefulInformation = lazy(() => import('./components/pages/UsefulInformation'));
const SizingGuides = lazy(() => import('./components/pages/SizingGuides'));
const ReturnInformation = lazy(() => import('./components/pages/ReturnInformation'));
const ReturnForm = lazy(() => import('./components/pages/ReturnForm'));
const PrivacyPolicy = lazy(() => import('./components/pages/PrivacyPolicy'));
const PaymentMethods = lazy(() => import('./components/pages/PaymentMethods'));
const DeliveryInformation = lazy(() => import('./components/pages/DeliveryInformation'));
const Contacts = lazy(() => import('./components/pages/Contacts'));

axios.defaults.baseURL = serverUrl;

// ReactGA.initialize('G-EK216L1EX4');

export default function App() {
  // useEffect(() => {
  //   ReactGA.send(window.location.pathname);
  // }, []);

  // const [gaLoaded, setGaLoaded] = useState(false);

  // useEffect(() => {
  //   if (!gaLoaded) {
  //     import('react-ga4').then((ReactGA) => {
  //       ReactGA.initialize('G-EK216L1EX4');
  //       ReactGA.send(window.location.pathname);
  //       setGaLoaded(true);
  //     });
  //   }
  // }, []);

  return (
    <React.Fragment>
      <GlobalProvider>
        <div id="container">
          <Layout />
          <CookieConsent
            location="bottom"
            buttonText="Приемам"
            cookieName="myAwesomeCookieName2"
            style={{ background: 'lightblue' }}
            buttonStyle={{
              color: 'white',
              fontSize: '13px',
              backgroundColor: '#1665C0',
              marginRight: '30px',
              borderRadius: '4px'
            }}
            expires={365}>
            Сайтът използва бисквитки (cookies) за подобряване на неговата ефективност.
            Продължавайки използването му, Вие се съгласявате с нашата {}
            <Link to="/deklaratsiq-za-poveritelnost" style={{ color: 'white' }}>
              “Политика за употреба на бисквитки”
            </Link>
          </CookieConsent>
          <div id="main-content">
            <Routes>
              <Route path={routerUrls.root} element={<Home />} />
              <Route path={routerUrls.products + '/*'} element={<Products />} />
              <Route
                path={
                  routerUrls.products +
                  '/:category/:subcategory/:categoryId/:subCategoryId/:materialId'
                }
                element={<Products />}
              />
              <Route
                path={
                  routerUrls.productsCategories +
                  '/:category/:subcategory/:categoryId/:subCategoryId'
                }
                element={<ProductsCategories />}
              />
              <Route path={routerUrls.search + '/:term'} element={<ProductsSearch />} />

              <Route path={routerUrls.product + '/:slug/:id'} element={<ProductView />} />

              <Route path={routerUrls.cart} element={<Cart />} />
              <Route path={routerUrls.checkout} element={<Checkout />} />
              <Route path={routerUrls.orderPlaced} element={<OrderPlaced />} />
              <Route
                path={routerUrls.contacts}
                element={
                  <Suspense fallback={<>...</>}>
                    <Contacts />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.contactForm}
                element={
                  <Suspense fallback={<>...</>}>
                    <ContactForm />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.deliveryInfo}
                element={
                  <Suspense fallback={<>...</>}>
                    <DeliveryInformation />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.paymentMethods}
                element={
                  <Suspense fallback={<>...</>}>
                    <PaymentMethods />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.privacyPolicy}
                element={
                  <Suspense fallback={<>...</>}>
                    <PrivacyPolicy />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.returnForm}
                element={
                  <Suspense fallback={<>...</>}>
                    <ReturnForm />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.returnInfo}
                element={
                  <Suspense fallback={<>...</>}>
                    <ReturnInformation />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.sizingGuides}
                element={
                  <Suspense fallback={<>...</>}>
                    <SizingGuides />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.termsAndConditions}
                element={
                  <Suspense fallback={<>...</>}>
                    <TermsAndConditions />
                  </Suspense>
                }
              />
              <Route
                path={routerUrls.usefulInfo}
                element={
                  <Suspense fallback={<>...</>}>
                    <UsefulInformation />
                  </Suspense>
                }
              />
              <Route path={routerUrls.submittedForm} element={<SubmittedForm />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <FooterBazar />
        </div>
      </GlobalProvider>
    </React.Fragment>
  );
}

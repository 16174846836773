import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { GlobalContext } from '../context/GlobalState';
import { useContext, useEffect } from 'react';
import AccordionMenu from './AccordionMenu';
import SearchBox from './SearchBox';
import axios from 'axios';
import { Typography } from '@mui/material';

export default function MobileNavBar() {
  const [state, setState] = React.useState({
    left: false
  });
  const { menu, setMenu } = useContext(GlobalContext);
  useEffect(async () => {
    if (!menu) {
      const { data } = await axios.get(`/menu`);
      setMenu(data);
    }
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const drawer = (anchor) => (
    <div style={{ padding: 15 }}>
      <SearchBox />
      {menu && (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}>
          <AccordionMenu categroyList={menu} />
        </Box>
      )}
    </div>
  );

  return (
    <React.Fragment key="left">
      <Button onClick={toggleDrawer('left', true)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingRight: 20
          }}>
          <MenuIcon
            sx={{
              fontSize: 36,
              color: 'gray'
            }}
          />
          <Typography
            style={{
              textTransform: 'none',
              color: 'gray',
              fontSize: 12
            }}>
            меню
          </Typography>
        </div>
      </Button>

      <Drawer anchor="left" open={state['left']} onClose={toggleDrawer('left', false)}>
        {drawer('left')}
      </Drawer>
    </React.Fragment>
  );
}

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { GlobalContext } from '../context/GlobalState';
import { useContext } from 'react';

export default function RadioButtonsGroup({ values }) {
  const { shippingOption, selectShippingOption } = useContext(GlobalContext);

  const [resultSelected, setResultSelected] = React.useState(shippingOption.name);

  const handleValueChange = (event) => {
    let selectedValue = event.target.value;
    setResultSelected(selectedValue);

    let selectedOption = values?.filter((item) => item.name === selectedValue)[0];

    selectShippingOption(selectedOption);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={resultSelected}
        onChange={handleValueChange}>
        {values.map((item) => (
          <FormControlLabel
            key={item.name}
            value={item.name}
            control={<Radio />}
            label={item.name + ' - ' + item.price + ' BGN'}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

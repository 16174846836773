import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { routerUrls } from 'constants';
import { GlobalContext } from '../context/GlobalState';
import { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { languages } from 'constants';
import ProductFilters from './ProductFilters';
import ProductsCategoriesCard from './ProductsCategoriesCard';
import ScrollToTop from './ScrollToTop'; //TODO: FIX PATH
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';

// MAIN COMPONENT TO SHOW THE PRODUCT CATEGORIES BY TYPE
export default function ProductsCategories() {
  const params = useParams();
  const { lang, menu } = useContext(GlobalContext);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentSubCategory, setCurrentSubCategory] = useState(null);
  const theme = useTheme();

  const { materialCategories, setMaterialCategories, categoryIdUrlParam, subcategoryIdUrlParam } =
    useContext(GlobalContext);

  useEffect(async () => {
    if (
      !materialCategories ||
      categoryIdUrlParam != params.categoryId ||
      subcategoryIdUrlParam != params.subCategoryId
    ) {
      const { data } = await axios.get(`/materialCategories?lang=bg`, {
        params
      });
      setMaterialCategories(data);
      // logic to get the selected category and subcategory path e.g. bed linen > sheets
    }
    if (languages.bg === lang) {
      let category = menu.find((categ) => {
        return categ.id === parseInt(params.categoryId);
      });
      setCurrentCategory(category);
      let subCategory = category.subCategories.find((subCat) => {
        return subCat.id === parseInt(params.subCategoryId);
      });
      setCurrentSubCategory(subCategory);
    }
  }, [params, menu]);

  const curatedImagesPerCategory = {
    // bed sheets
    1: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/dsd/553-33c-2.jpg', //SATEN
      'https://gotobed.eu/wp-content/uploads/files/m/R-145-2.jpg' //RANFORCE
    ],
    // pillows
    2: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/st/S-303-10.jpg',
      'https://gotobed.eu/wp-content/uploads/files/pc/941-1.jpg'
    ],
    // fitted sheets
    3: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/st/s-301-1.jpeg',
      'https://gotobed.eu/wp-content/uploads/files/s/939-BEGIE-1-1.jpg'
    ],
    // duvet cases
    4: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/sk/934-6.jpg',
      'https://gotobed.eu/wp-content/uploads/files/pk/R-108-1-1.jpg'
    ],
    // no 5th category !!!
    5: [
      '', //because subcategories start from 1 :)
      '',
      ''
    ],
    // round fitted sheets
    6: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/r/958-Ash-rose-light-round.jpg',
      'https://gotobed.eu/wp-content/uploads/files/r/R-118-round.jpg'
    ],
    // kids sheets
    7: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/sk/903-2-2.jpg',
      'https://gotobed.eu/wp-content/uploads/files/pk/R-131-front-2-1.jpg'
    ],
    // kids pillowcases
    8: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/pc/939-1.jpg',
      'https://gotobed.eu/wp-content/uploads/files/pc/159-1.jpg'
    ],
    // kids fitted sheets
    9: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/sk/934-2.jpg',
      'https://gotobed.eu/wp-content/uploads/files/pk/R-155-Front-2-3.jpg'
    ],
    // kids duvet covers
    10: [
      '', //because subcategories start from 1 :)
      'https://gotobed.eu/wp-content/uploads/files/sk/958-3.jpg',
      'https://gotobed.eu/wp-content/uploads/files/pk/R-160-front-2.jpg'
    ]
  };

  return (
    <ScrollToTop>
      {currentSubCategory && (
        <Helmet>
          <title>Продукти за {currentSubCategory.name_bg}. Размери по поръчка</title>
          <meta
            name="description"
            content="спално бельо, чаршаф, плик, калъфка, чаршаф с ластик, кръгъл чаршаф с ластик, бебешко, детско спално бельо, памучен сатен, ранфорс, памук"
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}
      <Container
        style={{
          backgroundColor: '',
          paddingTop: 0,
          justify: 'center',
          margin: 0,
          paddingBottom: '40px',
          minHeight: 400
        }}>
        <Grid container justify="center" spacing={3} style={{ backgroundColor: '', paddingTop: 0 }}>
          <Grid item lg={3} md={3} xs={12}>
            <ProductFilters />
          </Grid>

          <Grid item style={{ backgroundColor: '' }} lg={9} md={9} xs={12}>
            {currentCategory && currentSubCategory && (
              <Typography
                sx={{
                  pb: 3,
                  pt: 0, // Default padding for all screens
                  '@media (min-width: 600px)': {
                    // Example media query for screen width greater than 600px
                    pt: 3 // Change padding for screens larger than 600px
                  }
                }}>
                {/* THIS IS THE CATEGORY > SUBCATEGORY TEXT */}
                {currentCategory.name_bg} &gt; {currentSubCategory.name_bg}
              </Typography>
            )}
            <Grid container spacing={3}>
              {currentCategory &&
                currentSubCategory &&
                materialCategories &&
                materialCategories.map(
                  (item) =>
                    item.count > 0 && (
                      <Grid item key={item.id} lg={4} xs={6}>
                        <Link
                          to={
                            routerUrls.products +
                            '/' +
                            currentCategory.slug_bg +
                            '/' +
                            currentSubCategory.slug_bg +
                            '/' +
                            params.categoryId +
                            '/' +
                            params.subCategoryId +
                            '/' +
                            item.id
                          }
                          style={{ color: 'inherit', textDecoration: 'none' }}
                          key={item.id}>
                          <ProductsCategoriesCard
                            productCategories={item}
                            image={curatedImagesPerCategory[params.subCategoryId][item.id]}
                          />
                        </Link>
                      </Grid>
                    )
                )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ScrollToTop>
  );
}

import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ImageViewer from 'react-simple-image-viewer';
import FlexBox from './FlexBox';
import BazarAvatar from './BazarAvatar';

export default function ProductViewGridItemImage({ product }) {
  const imgGroup = product?.images?.map((a) => a.url);

  const [selectedImage, setSelectedImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const handleImageClick = (ind) => () => {
    setSelectedImage(ind);
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const moveRight = () => {
    const nextImageIdx = selectedImage + 1;
    if (imgGroup.length > nextImageIdx) {
      setSelectedImage(selectedImage + 1);
    } else {
      setSelectedImage(0);
    }
  };

  const moveLeft = () => {
    const nextImageIdx = selectedImage - 1;
    if (nextImageIdx >= 0) {
      setSelectedImage(nextImageIdx);
    } else {
      setSelectedImage(imgGroup.length - 1);
    }
  };

  return (
    <Grid
      item
      sm={4}
      xs={12}
      alignItems="center"
      style={{ backgroundColor: '', minHeight: '580px', height: '580px' }}>
      <Box style={{ backgroundColor: '' }}>
        <FlexBox justifyContent="center" mb={2} style={{ backgroundColor: '', minHeight: '480px' }}>
          <div
            style={{
              paddingTop: '200px'
            }}>
            <ArrowBack fontSize="small" color="inherit" onClick={moveLeft} />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              position: 'relative'
            }}>
            <img
              src={imgGroup[selectedImage]}
              onClick={() => openImageViewer(imgGroup.indexOf(imgGroup[selectedImage]))}
              alt={product.name_bg}
              width={330}
              // height={440}
              // height="auto"
              loading="eager"
              objectFit="contain"
            />

            {isViewerOpen && (
              <ImageViewer
                src={imgGroup}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                backgroundStyle={{
                  backgroundColor: 'rgba(0,0,0,0.9)'
                }}
              />
            )}
          </div>

          <div
            style={{
              paddingTop: '200px'
            }}>
            <ArrowForward fontSize="small" color="inherit" onClick={moveRight} />
          </div>
        </FlexBox>
        <FlexBox overflow="auto">
          {imgGroup.map((url, ind) => (
            <Box
              height={70}
              width={60}
              bgcolor=""
              borderRadius="0px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1.5px solid"
              style={{
                cursor: 'pointer'
              }}
              ml={ind === 0 ? 'auto' : 0}
              mr={ind === imgGroup.length - 1 ? 'auto' : '10px'}
              borderColor={selectedImage === ind ? 'primary.main' : 'grey.400'}
              onClick={handleImageClick(ind)}
              key={ind}>
              <BazarAvatar
                src={url}
                variant="square"
                height={70}
                width={60}
                alt={product.name_bg + '-' + { ind }}
              />
            </Box>
          ))}
        </FlexBox>
      </Box>
    </Grid>
  );
}

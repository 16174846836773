// export const serverUrl = process.env.REACT_APP_SERVER_URL;
export const serverUrl =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : 'https://gotobed.eu/api';

// default routerUrls is routerUrls_BG
export const routerUrls = {
  root: '/',
  sale: '/namalenie',
  product: '/produkt',
  products: '/produkti',
  productsCategories: '/kategorii',
  search: '/tursene',
  cart: '/koshtnitsa',
  checkout: '/checkout',
  orderPlaced: '/poruchkata-zavurshena',
  // footer pages
  contacts: '/kontakti',
  contactForm: '/forma-za-kontakti',
  deliveryInfo: '/dostavka-na-stokite',
  paymentMethods: '/nachini-za-plashtane',
  privacyPolicy: '/deklaratsiq-za-poveritelnost',
  returnForm: '/forma-za-vrushtane',
  returnInfo: '/vrushtane-na-zakupena-stoka',
  sizingGuides: '/tablitsi-s-razmeri',
  termsAndConditions: '/obshti-usloviq',
  usefulInfo: '/polezna-informaciq',
  submittedForm: '/forma-izpratena'
};

export const routerUrls_EN = {
  root: '/',
  sale: '/sale',
  product: '/product',
  products: '/products',
  productsCategories: '/productsCategories',
  search: '/search-results',
  cart: '/cart',
  checkout: '/checkout',
  orderPlaced: '/order-placed',
  // footer pages
  contacts: '/contacts',
  contactForm: '/contact-form',
  deliveryInfo: '/delivery-information',
  paymentMethods: '/payment-methods',
  privacyPolicy: '/privacy-policy',
  returnForm: '/return-form',
  returnInfo: '/return-information',
  sizingGuides: '/sizing-guides',
  termsAndConditions: '/terms-and-conditions',
  usefulInfo: '/useful-information',
  submittedForm: '/submitted-form'
};

export const routerUrls_RU = {
  root: '/',
  sale: '/sale',
  product: '/product',
  products: '/products',
  productsCategories: '/productsCategories',
  search: '/search-results',
  cart: '/cart',
  checkout: '/checkout',
  orderPlaced: '/order-placed',
  // footer pages
  contacts: '/contacts',
  contactForm: '/contact-form',
  deliveryInfo: '/delivery-information',
  paymentMethods: '/payment-methods',
  privacyPolicy: '/privacy-policy',
  returnForm: '/return-form',
  returnInfo: '/return-information',
  sizingGuides: '/sizing-guides',
  termsAndConditions: '/terms-and-conditions',
  usefulInfo: '/useful-information',
  submittedForm: '/submitted-form'
};

export const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440
};

export const layoutConstant = {
  topbarHeight: 40,
  grocerySidenavWidth: 280,
  containerWidth: 1200,
  mobileNavHeight: 64,
  headerHeight: 80,
  mobileHeaderHeight: 64
};

export const themeColors = {
  darkBlue: '#0c0e30',
  cherryRedBright: '#d64444',
  cherryRedDeep: '#a4014e',

  //dark royal purple
  darkPurple: '#68478D',

  //home squares
  lightYellow: '#f4d96e',
  lightBlue: '#8acee2',
  lightGreen: '#a7c083',
  lightPurple: '#8695bb',

  gunMetalGrey: '#818589'
};

export const stringConstants = {
  email: 'info@gotobed.eu',
  phone: '+359 888 936 738',
  workingHours: 'Пон-Пет 10:00-18:00'
};

export const languages = {
  bg: 'bg',
  en: 'en',
  ru: 'ru'
};

export const assets = {
  logo: 'https://gotobed.eu/wp-content/uploads/files/logo/gotobedlogo_1_50.png',
  logoWhite: 'https://gotobed.eu/wp-content/uploads/files/logo/gotobedlogo-white_1_2_50.png',
  standardSizingGuide:
    'https://gotobed.eu/wp-content/uploads/files/msc/standard-sizes-2048x978.jpg',
  sizingGuideFittedSheets:
    'https://gotobed.eu/wp-content/uploads/files/msc/RUKOVOD-SHEET-CVETNO-1000.jpg',
  sizingGuideDuvetCover:
    'https://gotobed.eu/wp-content/uploads/files/msc/PLIK-RUKOVOD-CVQT-1200.jpg',
  sizingGuidePillowCase: 'https://gotobed.eu/wp-content/uploads/files/msc/SIZING-GUIDE.jpg'
};

export const currency = {
  BGN: 'BGN'
};

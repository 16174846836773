import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ListItem from '@mui/material/ListItem';

const LabTab = ({ items, label }) => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
            {items.length > 0 && <Tab label={label} value="1" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          {items?.map((i, idx) => (
            <ListItem key={idx} sx={{ display: 'list-item' }}>
              {i}
            </ListItem>
          ))}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default LabTab;

import FlexBox from './FlexBox';
import { Span } from './Typography';
import CallOutlined from '@mui/icons-material/CallOutlined';
import MailOutline from '@mui/icons-material/MailOutlineSharp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Container, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { layoutConstant } from 'constants';
import { stringConstants } from 'constants';
import React from 'react';
import { useTheme } from '@mui/material/styles';

const TopbarWrapper = styled('div')(({ theme }) => ({
  height: layoutConstant.topbarHeight,
  backgroundColor: 'white',
  borderBottom: '1px solid lightgrey',
  color: 'black',
  fontSize: 12,
  marginBottom: '5px',
  '& .topbarRight': {
    '& .link': {
      paddingRight: 30,
      color: 'black'
    },
    '@media only screen and (max-width: 900px)': {
      '& .link': {
        display: 'none'
      }
    }
  },
  '& .topbarLeft': {
    '& .link': {
      paddingLeft: 30,
      color: 'black'
    },
    '@media only screen and (max-width: 900px)': {
      '& .link': {
        display: 'none'
      }
    }
  },
  '& .smallRoundedImage': {
    height: 15,
    width: 25,
    borderRadius: 2
  },
  '& .handler': {
    height: layoutConstant.topbarHeight
  },
  '& .menuTitle': {
    fontSize: 12,
    marginLeft: '0.5rem',
    fontWeight: 600
  },
  '& .menuItem': {
    minWidth: 100
  },
  '& .marginRight': {
    marginRight: '1.25rem'
  }
}));

// GOTOBED TOPBAR COMPONENT
const Topbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <TopbarWrapper>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          backgroundColor: ''
        }}>
        <FlexBox className="topbarLeft" alignItems="center">
          <FlexBox alignItems="center">
            <CallOutlined fontSize="small" sx={{ p: 0.5, color: 'grey.500' }} />
            <Span color="grey.700">{stringConstants.phone}</Span>
          </FlexBox>

          <FlexBox alignItems="center" ml={2.5}>
            <MailOutline fontSize="small" sx={{ p: 0.5, color: 'grey.500' }} />
            <Span color="grey.700">{stringConstants.email}</Span>
          </FlexBox>
        </FlexBox>

        {!isMobile && (
          <FlexBox className="topbarRight" alignItems="center">
            <AccessTimeIcon fontSize="small" sx={{ p: 0.5, color: 'grey.500' }} />
            <Span className="title" color="grey.700">
              {stringConstants.workingHours}
            </Span>
          </FlexBox>
        )}
      </Container>
    </TopbarWrapper>
  );
};

export default Topbar;

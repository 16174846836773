import React from 'react';
import BazarImage from './BazarImage';
import { Box, styled } from '@mui/material';
import { GlobalContext } from '../context/GlobalState';
import { useContext } from 'react';
import { strings } from 'localisation';
import { languages } from 'constants';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// MAIN COMPONENT FOR INGRESS INTO A SPECIFIC CATEGORY AND MATERIAL PRODUCT COLLECTION
export default function ProductsCategoriesCard({ productCategories, image }) {
  const { lang } = useContext(GlobalContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div
      style={{
        position: 'relative',
        borderRadius: '20px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        minHeight: '240',
        minWidth: '180',
        backgroundColor: 'white'
      }}>
      <BazarImage
        alt={productCategories.name_bg}
        height={isMobile ? 240 : 350}
        widht={180}
        src={image}
        style={{
          display: 'block',
          position: 'relative',
          width: '100%',
          borderRadius: '20px'
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          borderRadius: '20px'
        }}></div>
      <button
        style={{
          position: 'absolute',
          bottom: '-10%',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'white',
          borderRadius: '0',
          padding: '10px 20px',
          border: 'none',
          minWidth: '100%',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          fontSize: 14
        }}>
        {languages.bg === lang && productCategories.name_bg}
        <br />
        {productCategories.count} {strings[lang].products}
      </button>
    </div>
  );
}

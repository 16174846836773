import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Product from './Product';
import Button from '@mui/material/Button';
import ProductFilters from './ProductFilters';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';
import { strings } from 'localisation';
import createGAevent from 'helpers';
import fs from 'fs';
import { Helmet } from 'react-helmet';
import { languages } from 'constants';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// GOTOBED ALL PRODUCTS COMPONENTS
const Products = () => {
  const {
    productList,
    loadProductList,
    categoryIdUrlParam,
    setCategoryIdUrlParam,
    subcategoryIdUrlParam,
    setSubCategoryIdUrlParam,
    materialIdUrlParam,
    setMaterialIdUrlParam,
    clearProductList,
    pageUrlParam,
    setPageUrlParam,
    materialCategories
  } = useContext(GlobalContext);
  const { lang, menu } = useContext(GlobalContext);
  const params = useParams(); // for some reason params know that /1/2 is mapped to categoryId and subCatId. How? Why?
  const search = useLocation().search;
  const [showLoadButton, setShowLoadButton] = useState(true);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentSubCategory, setCurrentSubCategory] = useState(null);
  const [currentMaterialCategory, setCurrentMaterialCategory] = useState(null);

  const page = new URLSearchParams(search).get('page');
  const size = new URLSearchParams(search).get('size');
  const default_min_size = 12; //set in the backedn unless changed form the frontend to something else
  params['size'] = size;
  params['page'] = page;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // WHRE WE SET THE CATEGORY AND SUBCATEGORY ATTRIBUTES HERE???
  const fetchProductListData = async (currentUrlParam) => {
    // params PAGE starts from 0 !!!!!
    params['productId'] = null;
    params['page'] = currentUrlParam;
    try {
      const { data: response } = await axios.get(`/products/?lang=bg`, {
        params
      });
      let newpage = parseInt(currentUrlParam) + 1;
      setPageUrlParam(newpage);

      response.forEach((product) => {
        product['images'].sort(function (a, b) {
          return a['Product_Image'].position - b['Product_Image'].position;
        });
      });

      // response.sort(function (a, b) {
      //   return a.position - b.position;
      // });

      return response;
    } catch (error) {
      if (error.response) {
        // Not in the 200 response range
        let errorMessage = {
          error:
            '--------------------------------------------------------------------------' +
            new Date() +
            '-  Error while trying to fetchProductListData!!!',
          error_log: error
        };
        fs.appendFile(
          'error_logs.json',
          JSON.stringify(errorMessage) +
            '--------------------------------------------------------------------------------------------------------------'
        );
      }
    }
  };

  const fetchData = async (currentUrlParam) => {
    const data = await fetchProductListData(currentUrlParam);
    // maybe there is a proper way to do this
    if (data != 'error') {
      if (data.length < default_min_size) {
        setShowLoadButton(false);
      }
      await loadProductList(data);
    }
  };

  const clearData = async () => {
    await clearProductList();
  };

  useEffect(() => {
    if (
      (categoryIdUrlParam != null || subcategoryIdUrlParam != null || materialIdUrlParam != null) &&
      (categoryIdUrlParam != params.categoryId ||
        subcategoryIdUrlParam != params.subCategoryId ||
        materialIdUrlParam != params.materialId)
    ) {
      clearData();
      fetchData(0);
    }
    if (
      (categoryIdUrlParam == null ||
        subcategoryIdUrlParam == null ||
        materialIdUrlParam == null ||
        categoryIdUrlParam != params.categoryId ||
        subcategoryIdUrlParam != params.subCategoryId ||
        materialIdUrlParam != params.materialId) &&
      typeof params.categoryId != 'undefined' &&
      typeof params.subCategoryId != 'undefined' &&
      typeof params.materialId != 'undefined'
    ) {
      setCategoryIdUrlParam(params.categoryId);
      setSubCategoryIdUrlParam(params.subCategoryId);
      setMaterialIdUrlParam(params.materialId);
    }
  }, [params]);

  useEffect(() => {
    if (productList.length === 0) {
      fetchData(0);
    }
  }, []);

  const getMoreProducts = () => {
    fetchData(pageUrlParam);
    createGAevent('Products', 'LOAD_MORE_BUTTON_BUTTON', 'subCategoryId-' + params.subCategoryId);
    createGAevent(
      'Products',
      `LOAD_MORE_BUTTON-subCat-${params.subCategoryId}`,
      'subCategoryId-' + params.subCategoryId
    );
  };

  const persistScrollPosition = (id) => {
    sessionStorage.setItem('scroll-position-product-id-marker', id);
  };

  useEffect(async () => {
    if (languages.bg === lang && menu != null) {
      let category = menu.find((categ) => {
        return categ.id == parseInt(params.categoryId);
      });
      setCurrentCategory(category);
      let subCategory = category.subCategories.find((subCat) => {
        return subCat.id == parseInt(params.subCategoryId);
      });
      setCurrentSubCategory(subCategory);

      let material = materialCategories.find((material) => material.id == params.materialId);
      setCurrentMaterialCategory(material.name_bg);
    }
  }, []);

  return (
    <>
      {currentSubCategory != null && currentMaterialCategory && (
        <Helmet>
          <title>
            Колекция продукти {currentSubCategory.name_bg} {currentMaterialCategory}
          </title>
          <meta
            name="description"
            content="спално бельо, чаршаф, плик, калъфка, чаршаф с ластик, кръгъл чаршаф с ластик, бебешко, детско спално бельо, размери по поръчка, памучен сатен, ранфорс, памук"
          />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
      )}
      <Container style={{ backgroundColor: '', paddingTop: 0, justify: 'center' }}>
        <Grid container justify="center" spacing={3} style={{ backgroundColor: '' }}>
          <Grid item lg={3} md={3} xs={12}>
            <ProductFilters />
          </Grid>

          <Grid
            item
            style={{
              backgroundColor: '',
              paddingTop: isMobile ? 0 : 30
            }}
            lg={9}
            md={9}
            xs={12}>
            {currentSubCategory != null && currentMaterialCategory && (
              <Typography
                style={{
                  padding: 5,
                  paddingBottom: 20,
                  alignContent: 'center',
                  fontWeight: 300
                }}>
                Колекция от категория <b>{currentSubCategory.name_bg}</b> -{' '}
                {currentMaterialCategory}
              </Typography>
            )}
            <Grid container spacing={3} style={{ backgroundColor: 'white', minHeight: '800' }}>
              {productList &&
                productList.map((ProductItem) => (
                  <Grid item key={ProductItem.id} lg={3} md={3} xs={6}>
                    <Product Product={ProductItem} onSelect={persistScrollPosition} />
                  </Grid>
                ))}
            </Grid>

            <Box textAlign="center" sx={{ p: 1 }}>
              {showLoadButton && (
                <Button variant="contained" onClick={getMoreProducts}>
                  {strings[lang].loadMoreButton}
                </Button>
              )}
              {!showLoadButton && <Typography> {strings[lang].noMoreProducts}</Typography>}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default Products;

import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import 'pure-react-carousel/dist/react-carousel.es.css';
import LabTab from './LabTab';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { GlobalContext } from '../context/GlobalState';
import { languages } from 'constants';
import ScrollToTop from './ScrollToTop';
import ProductViewGridItemImage from './ProductViewGridItemImage';
import ProductViewGridItemIntro from './ProductViewGridItemIntro';
import ProductViewGridItemAction from './ProductViewGridItemAction';
import { strings } from 'localisation';
import fs from 'fs';

export default function ProductView() {
  const location = useLocation();
  const { lang } = useContext(GlobalContext);
  const [additionalProductData, setAdditionalProductData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [priceRangeData, setPriceRangeData] = useState(null);
  const [description, setDescription] = useState([]);
  const params = useParams();

  const [variationPrice, setVariationPrice] = useState(null);

  const fetchAddionalProductData = async () => {
    try {
      const { data: response } = await axios.get(`/singleProduct/?lang=bg`, {
        params
      });
      return response[0];
    } catch (error) {
      let errorMessage = {
        error:
          '--------------------------------------------------------------------------' +
          new Date() +
          '-  Error while trying to fetchAdditionalProductData!!!',
        error_log: error
      };
      fs.appendFile(
        'error_logs.json',
        JSON.stringify(errorMessage) +
          '--------------------------------------------------------------------------------------------------------------'
      );
    }
  };

  const fetchAdditioanlData = async () => {
    const data = await fetchAddionalProductData();
    // maybe there is a proper way to do this
    if (data != 'error') {
      setAdditionalProductData(data);
    }
  };

  const fetchMainProductData = async () => {
    try {
      const { data: response } = await axios.get(`/products/?lang=bg`, {
        params
      });

      return response[0];
    } catch (error) {
      if (error.response) {
        // Not in the 200 response range
        return 'error';
      } else {
        return 'error';
      }
    }
  };

  const fetchMainData = async () => {
    const data = await fetchMainProductData();
    // maybe there is a proper way to do this
    if (data != 'error') {
      setProductData(data);
    }
  };

  const fetchPriceRange = async (productId) => {
    try {
      params['productId'] = productId;
      const { data: response } = await axios.get(`/price/`, {
        params
      });

      let priceRange = [];
      let minPrice = null;
      let maxPrice = null;

      if (response && response.length > 1) {
        minPrice = Math.min.apply(
          null,
          response.map((obj) => parseFloat(obj.price_bgn))
        );
        priceRange.push(minPrice.toFixed(2));

        maxPrice = Math.max.apply(
          null,
          response.map((obj) => parseFloat(obj.price_bgn))
        );
        priceRange.push(maxPrice.toFixed(2));
        return priceRange;
      } else if (response.length === 1) {
        minPrice = parseFloat(response[0].price_bgn);
        priceRange.push(minPrice.toFixed(2));
        return priceRange;
      } else {
        return priceRange;
      }
    } catch (error) {}
  };

  const getPriceRange = async (productId) => {
    const priceRangeData = await fetchPriceRange(productId);
    setPriceRangeData(priceRangeData);
  };

  React.useEffect(() => {
    fetchAdditioanlData();
    if (location?.state !== null) {
      if (location?.state?.Product !== null) {
        setProductData(location.state.Product);
      }
      if (location?.state?.priceRange !== null) {
        setPriceRangeData(location.state.priceRange);
      }
    } else {
      if (productData === null) {
        fetchMainData();
      }
      if (priceRangeData === null) {
        const productId = params['id'];
        getPriceRange(productId);
      }
    }
  }, []);

  React.useEffect(() => {
    if (
      additionalProductData &&
      languages.bg === lang &&
      'description_bg' in additionalProductData &&
      typeof additionalProductData.description_bg !== 'undefined' &&
      additionalProductData.description_bg != null
    ) {
      let description_result = additionalProductData.description_bg.split(';').map(function (item) {
        return item.trim();
      });
      setDescription(description_result);
    }
  }, [additionalProductData]);

  return (
    <ScrollToTop>
      <Container style={{ backgroundColor: '', position: 'static', paddingTop: 10 }}>
        <Grid container>
          {/* Helment is already included in ProductViewGridItemIntro so if Helment is definded here it will get overwitten by Helmet in child component */}
          {productData && <ProductViewGridItemImage product={productData} />}
          {productData && (
            <ProductViewGridItemIntro
              product={productData}
              priceRange={priceRangeData}
              additionalProductData={additionalProductData}
              variationPrice={variationPrice}
            />
          )}
          {additionalProductData && productData && (
            <ProductViewGridItemAction
              product={productData}
              additionalProductData={additionalProductData}
              variationPrice={variationPrice}
              setVariationPrice={setVariationPrice}
            />
          )}
        </Grid>

        <LabTab items={description} label={strings[lang].description} />
      </Container>
    </ScrollToTop>
  );
}

import Image from './BazarImage';
import FlexBox from './FlexBox';
import { Span } from './Typography';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Remove from '@mui/icons-material/Remove';
import { Button, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback } from 'react';
import ProductCard7Style from './ProductCard7Style';
import { GlobalContext } from '../context/GlobalState';
import { useContext } from 'react';
import { routerUrls } from 'constants';
import { Link } from 'react-router-dom';
import BazarButton from './BazarButton';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ProductCard7 = ({ item }) => {
  const { changeCartItemQty } = useContext(GlobalContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleCartAmountChange = useCallback(
    (amount, product) => () => {
      return changeCartItemQty({ ...product, quantity: amount });
    },
    []
  );

  return (
    <ProductCard7Style>
      {!isMobile && (
        <Link
          to={routerUrls.product + '/' + item.slug_bg + '/' + item.id} //here we do the connection with the ProductView
          style={{ color: 'inherit', textDecoration: 'none' }}>
          <Image src={item.imgUrl} width={140} display="block" alt={item.name} />
        </Link>
      )}

      <FlexBox
        className="product-details"
        flexDirection="column"
        justifyContent="space-between"
        minWidth="0px"
        width="100%">
        {isMobile && (
          <div
            style={{
              backgroundColor: '',
              paddingBottom: 10,
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Link
              to={routerUrls.product + '/' + item.slug_bg + '/' + item.id} //here we do the connection with the ProductView
              style={{ color: 'inherit', textDecoration: 'none' }}>
              <Image src={item.imgUrl} style={{ maxWidth: '150px' }} alt={item.name} />
            </Link>
          </div>
        )}

        <FlexBox flexDirection="row" alignItems="flex-start">
          <Link
            to={routerUrls.product + '/' + item.slug_bg + '/' + item.id} //here we do the connection with the ProductView
            style={{ color: 'inherit', textDecoration: 'none' }}>
            <Span
              fontWeight="600"
              fontSize="15px"
              mb={0}
              mr={1}
              pr={10}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3
              }}>
              {item.name}
            </Span>
          </Link>
          <IconButton
            size="small"
            sx={{
              padding: '4px',
              ml: '10px'
            }}
            onClick={handleCartAmountChange(0, item)}>
            <Close fontSize="small" />
          </IconButton>
        </FlexBox>

        {item.attributes &&
          Object.keys(item.attributes).map(function (attribute, index) {
            return (
              <Span color="grey.600" fontSize="12px" key={item.attributes[attribute].id}>
                {item.attributes[attribute].label} : {item.attributes[attribute].value} cm
              </Span>
            );
          })}

        <FlexBox justifyContent="space-between" alignItems="flex-end">
          <FlexBox flexWrap="wrap" alignItems="center">
            <Span color="grey.600" mr={1}>
              {item.price} BGN x {item.quantity}
            </Span>
            <Span fontWeight={600} color="primary.main" mr={2}>
              {(item.price * item.quantity).toFixed(2)} BGN
            </Span>
          </FlexBox>

          <FlexBox alignItems="center">
            <BazarButton
              variant="outlined"
              color="primary"
              disabled={item.quantity === 1}
              sx={{
                height: '32px',
                width: '32px',
                borderRadius: '300px'
              }}
              onClick={handleCartAmountChange(item.quantity - 1, item)}>
              <Remove fontSize="small" />
            </BazarButton>
            <Span mx={1} fontWeight="600" fontSize="15px" padding="10px">
              {item.quantity}
            </Span>
            <BazarButton
              variant="outlined"
              color="primary"
              sx={{
                height: '32px',
                width: '32px',
                borderRadius: '300px'
              }}
              onClick={handleCartAmountChange(item.quantity + 1, item)}>
              <Add fontSize="small" />
            </BazarButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ProductCard7Style>
  );
};

export default ProductCard7;

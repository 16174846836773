import React, { Fragment } from 'react';
import Header from './Header';
import Topbar from './Topbar';
import NavBar from './NavBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Layout() {
  const theme = useTheme();
  const navBarMatches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Fragment>
      <Topbar />
      <Header />
      <div className="section-after-sticky" style={{ height: navBarMatches ? '30px' : '0px' }}>
        {navBarMatches && <NavBar />}
      </div>
    </Fragment>
  );
}

import React from 'react';
import BazarCard from './BazarCard';
import FlexBox from './FlexBox';
import { Box, Container, MenuItem, styled } from '@mui/material';
import { routerUrls } from 'constants';
import { GlobalContext } from '../context/GlobalState';
import { useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const navLinkStyle = {
  cursor: 'pointer',
  marginRight: '2rem',
  transition: 'color 150ms ease-in-out',
  '&:hover': {
    color: 'primary.main'
  },
  '&:last-child': {
    marginRight: '0'
  }
};

//BazarCard
const NavBarWrapper = styled(Container)(({ theme }) => ({
  display: 'block',
  position: 'relative',
  borderBottom: '1px solid lightgray',
  width: '100%',
  [theme.breakpoints.down('sx')]: {
    display: 'none'
  }
}));
const InnerContainer = styled(Container)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%'
}));

// GOTOBED NAVBAR COMPONENTS
export default function NavBar() {
  const { menu, setMenu } = useContext(GlobalContext);
  useEffect(async () => {
    if (!menu) {
      const { data } = await axios.get(`/menu`);
      setMenu(data);
    }
  }, []);

  const displayNavbar = () => {
    return menu?.map((category) => {
      return (
        <FlexBox
          position="relative"
          flexDirection="column"
          alignItems="center"
          key={category.id}
          sx={{
            '&:hover': {
              '& > .child-nav-item': {
                display: 'block'
              }
            }
          }}>
          {/* main category title name */}
          <Box sx={navLinkStyle}>{category.name_bg}</Box>
          <Box
            className="child-nav-item"
            sx={{
              display: 'none',
              position: 'absolute',
              left: 0,
              top: '100%',
              zIndex: 5
            }}>
            <BazarCard
              sx={{
                mt: '1.25rem',
                py: '0.5rem',
                minWidth: '230px'
              }}
              elevation={3}>
              {category.subCategories.map((subCategory) => {
                return (
                  <Link
                    to={
                      routerUrls.productsCategories +
                      '/' +
                      category.slug_bg +
                      '/' +
                      subCategory.slug_bg +
                      '/' +
                      category.id +
                      '/' +
                      subCategory.id
                    }
                    style={{ color: 'inherit', textDecoration: 'none' }}
                    key={subCategory.id}>
                    {/* sub category title name */}
                    <MenuItem>{subCategory.name_bg}</MenuItem>
                  </Link>
                );
              })}
            </BazarCard>
          </Box>
        </FlexBox>
      );
    });
  };

  return (
    <NavBarWrapper maxWidth={false} elevation={2} hoverEffect={false}>
      <InnerContainer
        sx={{
          justifyContent: 'center',
          pb: '10px'
        }}>
        <FlexBox>{displayNavbar()}</FlexBox>
      </InnerContainer>
    </NavBarWrapper>
  );
}

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

export default function ToastNotification({
  open,
  handleCloseNotification,
  message,
  time = 3000,
  position,
  type = 'default',
  bckgrColor = 'lightgreen',
  textColor = 'black'
}) {
  let vertical = 'bottom';
  let horizontal = 'right';
  if (position == 'top-center') {
    vertical = 'top';
    horizontal = 'center';
  }

  return (
    <Snackbar
      bodyStyle={{ backgroundColor: 'teal', color: 'coral' }}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={2000}
      onClose={handleCloseNotification}
      key={vertical + horizontal}>
      <SnackbarContent
        style={{
          backgroundColor: bckgrColor,
          color: textColor
        }}
        message={message}
      />
    </Snackbar>
  );
}

import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CheckoutForm2 from './CheckoutForm2';
import CheckoutSummary2 from './CheckoutSummary2';
import { useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';

const paymentOptions = [
  {
    name: 'Плащане при доставка.(в брой/банкова карта)',
    description:
      'Плащане при доставка, в брой или чрез банкова карта, при получаване на доставката.'
  },
  {
    name: 'Банков превод',
    description:
      'Направете плащането директно в банковата ни сметка. Използвайте уникалния идентификационен номер на поръчката като основание за плащане. Поръчката няма да бъде изпратена преди получаването на превод по нашата сметка. Прочетете повече в Начини на плащане.'
  }
];

export default function Checkout() {
  const { cartList, shippingOption } = useContext(GlobalContext);

  const [paymentOptionSelected, setPaymentOptionSelected] = React.useState(paymentOptions[0].name);

  const subtotal =
    cartList.reduce(
      (accumulator, item) => accumulator + parseFloat(item.price) * item.quantity,
      0
    ) || 0;
  const total = subtotal + parseFloat(shippingOption.price);

  return (
    <Container style={{ backgroundColor: '', paddingTop: 30, justify: 'center' }}>
      <Grid container spacing={3}>
        <Grid item lg={5} md={5} xs={12}>
          <CheckoutSummary2
            paymentOptionSelected={paymentOptionSelected}
            paymentOptions={paymentOptions}
            setPaymentOptionSelected={setPaymentOptionSelected}
            subtotalOrderPrice={subtotal}
            totalOrderPrice={total}
          />
        </Grid>
        <Grid item lg={7} md={7} xs={12}>
          <CheckoutForm2
            paymentOptionSelected={paymentOptionSelected}
            subtotalOrderPrice={subtotal}
            totalOrderPrice={total}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

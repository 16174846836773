import React, { useContext, Suspense } from 'react';
import { Box, CircularProgress } from '@mui/material';
import CarouselSection from './CarouselSection';
import HomeSectionPromo from './HomeSectionPromo';
import { strings } from 'localisation';
import { themeColors } from 'constants';
import { GlobalContext } from 'context/GlobalState';
import { Helmet } from 'react-helmet';
// import AdobeStock_blue_opt from '../images/AdobeStock_blue_opt.webp';
// import AdobeStock_room_nopt from '../images/AdobeStock_room_nopt.webp';
// import AdobeStock_pink_opt from '../images/AdobeStock_pink_opt.webp';

const PopularProducts = React.lazy(() => import('./PopularProducts'));

//TODO: LOCALISE
const carouselDataArray = [
  {
    text: 'Bed Linen. 100% Cotton.',
    img_url: 'https://gotobed.eu/wp-content/uploads/files/msc/mob/AdobeStock_blue_opt_mob.webp'
  },
  {
    text: 'Standard and Custom Sizes',
    img_url: 'https://gotobed.eu/wp-content/uploads/files/msc/mob/AdobeStock_room_opt_mob.webp'
  },
  {
    text: 'Made in BULGARIA',
    img_url: 'https://gotobed.eu/wp-content/uploads/files/msc/mob/AdobeStock_pink_opt_mob.webp'
  }
];

export default function Home() {
  const { lang } = useContext(GlobalContext);

  const infoServices = [
    {
      identifier: 'bedLinen',
      icon: 'HandLoveIcon',
      title: strings[lang].squareTitle1,
      subtitle: strings[lang].squareSubTitle1,
      colorItem: themeColors.lightYellow
    },
    {
      identifier: 'kidsBedLinen',
      icon: 'Truck',
      title: strings[lang].squareTitle2,
      subtitle: strings[lang].squareSubTitle2,
      colorItem: themeColors.lightBlue
    },
    {
      identifier: 'madeInBG',
      icon: 'PublicIcon',
      title: strings[lang].squareTitle3,
      subtitle: strings[lang].squareSubTitle3,
      colorItem: themeColors.lightGreen
    },
    {
      identifier: 'moneyRules',
      icon: 'TimeIcon',
      title: strings[lang].squareTitle4,
      subtitle: strings[lang].squareSubTitle4,
      colorItem: themeColors.lightPurple
    }
  ];

  return (
    <>
      <Helmet>
        <title>GoToBed - Спално бельо</title>
        <meta
          name="description"
          content="спално бельо, чаршаф, плик, калъфка, чаршаф с ластик, кръгъл чаршаф с ластик, бебешко, детско спално бельо, размери по поръчка, памучен сатен, ранфорс, памук"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div style={{ backgroundColor: '', paddingTop: 0 }}>
        <CarouselSection carouselData={carouselDataArray} />

        <Box mb={6} style={{ paddingTop: 20 }}>
          <HomeSectionPromo serviceList={infoServices} />
        </Box>

          <Box
            style={{ height: 400 }}
            mb={2}
            mt={3}
            display="flex"
            justifyContent="center"
            alignItems="center">
            <Suspense
              fallback={
                <>
                  <CircularProgress />
                </>
              }>
              <PopularProducts />
            </Suspense>
          </Box>
      </div>
    </>
  );
}

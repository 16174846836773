import React, { useEffect, useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { GlobalContext } from '../context/GlobalState';
import ScrollToTop from './ScrollToTop'; //TODO: FIX PATH
import { strings } from 'localisation';
import ProductFilters from './ProductFilters';
import createGAevent from 'helpers';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function PageNotFound() {
  const { lang } = useContext(GlobalContext);
  const location = useLocation();

  useEffect(() => {
    createGAevent('PageNotFound', `PAGE_NOT_FOUND-${location}`, 'PAGE_NOT_FOUND');
  }, []);

  return (
    <>
      <Helmet>
        <title>Page not found</title>
        <meta name="errorpage" content="true" />
        <meta name="errortype" content="404 - Not Found" />
        <meta name="prerender-status-code" content="404" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ScrollToTop>
        <Container style={{ backgroundColor: '', paddingTop: 20, justify: 'center' }}>
          <Grid container justify="center" spacing={3} style={{ backgroundColor: '' }}>
            <Grid item lg={3} xs={12}>
              <ProductFilters />
            </Grid>
            <Grid item style={{ backgroundColor: '' }} lg={9} xs={12}>
              <Grid container spacing={3}>
                <Typography sx={{ pt: 5, pl: 3 }}>{strings[lang].pageNotFound}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </ScrollToTop>
    </>
  );
}

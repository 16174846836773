import Truck from './Truck';
// import MoneyGuarantee from './MoneyGuarantee';
// import AlarmClock from './AlarmClock';
// import Payment from './Payment';
import PublicIcon from '@mui/icons-material/PublicSharp';
import HandLoveIcon from '@mui/icons-material/VolunteerActivismSharp';
import TimeIcon from '@mui/icons-material/HistoryToggleOffSharp';

const appIcons = {
  // Payment,
  // MoneyGuarantee,
  // AlarmClock,
  Truck,
  PublicIcon,
  HandLoveIcon,
  TimeIcon
};
export default appIcons;

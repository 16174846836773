import BazarMenu from './BazarMenu';
import FlexBox from './FlexBox';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';
import { Box, Card, MenuItem, TextField } from '@mui/material';
import TouchRipple from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import { debounce } from '@mui/material/utils';
import React, { useCallback, useEffect, useRef, useState, useContext } from 'react'; // styled components
import { strings } from 'localisation';
import { GlobalContext } from 'context/GlobalState';
import { useNavigate } from 'react-router-dom';
import { routerUrls } from 'constants';

export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginRight: 6
})); // also used in the GrocerySearchBox components

export const SearchResultCard = styled(Card)(() => ({
  position: 'absolute',
  top: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  width: '100%',
  zIndex: 99
}));
const DropDownHandler = styled(FlexBox)(({ theme }) => ({
  borderTopRightRadius: 300,
  borderBottomRightRadius: 300,
  whiteSpace: 'pre',
  borderLeft: `1px solid ${theme.palette.text.disabled}`,
  [theme.breakpoints.down('xs')]: {
    display: 'none'
  }
}));

const SearchBox = () => {
  const [category, setCategory] = useState('All Categories');
  const [resultList, setResultList] = useState([]);
  const parentRef = useRef();
  const { lang } = useContext(GlobalContext);
  let navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const handleCategoryChange = (cat) => () => {
    setCategory(cat);
  };

  const search = debounce((e) => {
    const value = e.target?.value;
    setSearchTerm(value);
    if (!value) setResultList([]);
    else setResultList(dummySearchResult);
  }, 200);

  const hanldeSearch = useCallback((event) => {
    event.persist();
    search(event);
  }, []);

  const handleDocumentClick = () => {
    setResultList([]);
  };

  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  const categoryDropdown = (
    <BazarMenu
      direction="left"
      handler={
        <DropDownHandler
          alignItems="center"
          bgcolor="grey.100"
          height="100%"
          px={3}
          color="grey.700"
          component={TouchRipple}>
          <Box mr={0.5}>{category}</Box>
          <KeyboardArrowDownOutlined fontSize="small" color="inherit" />
        </DropDownHandler>
      }>
      {categories.map((item) => (
        <MenuItem key={item} onClick={handleCategoryChange(item)}>
          {item}
        </MenuItem>
      ))}
    </BazarMenu>
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNavigate();
    }
  };

  const handleNavigate = () => {
    navigate(`${routerUrls.search}` + '/' + `${searchTerm}`);
  };

  return (
    <Box
      position="relative"
      flex="1 1 0"
      maxWidth="670px"
      mx="auto"
      {...{
        ref: parentRef
      }}>
      <TextField
        variant="outlined"
        placeholder={strings[lang].searchBar}
        fullWidth
        onChange={hanldeSearch}
        onKeyDown={handleKeyDown}
        InputProps={{
          sx: {
            padding: 1,
            height: 44,
            borderRadius: 300,
            paddingRight: 0,
            color: 'grey.700',
            overflow: 'hidden',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main'
            }
          },
          // startAdornment: categoryDropdown,
          endAdornment: (
            <SearchOutlinedIcon sx={{ padding: 1 }} fontSize="medium" onClick={handleNavigate} />
          )
        }}
      />

      {!!resultList.length && (
        <SearchResultCard elevation={2}>
          {resultList.map(
            (item) => (
              // <Link href={`/product/search/${item}`} key={item}>
              <MenuItem key={item}>{item}</MenuItem>
            )
            // </Link>
          )}
        </SearchResultCard>
      )}
    </Box>
  );
};

const categories = [
  'All Categories',
  'Bed Linen',
  'Bed Sheets',
  'Fitted Sheets',
  'Pillowcases',
  'Baby Bedding'
];

const dummySearchResult = [];
export default SearchBox;

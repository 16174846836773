import React from 'react';
import { Container, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import FlexBox from '../FlexBox';
import { Span, H3, H4 } from '../Typography';
import { Divider } from '@mui/material';
import { useEffect, useContext } from 'react';
import { GlobalContext } from 'context/GlobalState';
import { currency } from 'constants';
import { strings } from 'localisation';
import ScrollToTop from '../ScrollToTop';
import Image from '../BazarImage';
import createGAevent from 'helpers';
import { Directions, TypeSpecimen } from '@mui/icons-material';
import { Helmet } from 'react-helmet';

export default function OrderPlaced() {
  const { lang } = useContext(GlobalContext);
  const { state } = useLocation();

  const { clearCartList } = useContext(GlobalContext);

  useEffect(() => {
    clearCartList();
    createGAevent(
      'OrderPlaced',
      'ORDER_COMPLETED',
      'orderId-' + state.values.uuidOrder + '---orderTotal-' + state.values.total
    );

    createGAevent(
      'OrderPlaced',
      `ORDER_COMPLETED-orderId-${state.values.uuidOrder}-orderTotal-${state.values.total}`,
      'orderId-' + state.values.uuidOrder + '---orderTotal-' + state.values.total
    );
  }, []);

  const getSubTotalPricePresentation = () => {
    return state.values.subtotal + ' ' + currency.BGN;
  };

  const getTotalPricePresentation = () => {
    return state.values.total + ' ' + currency.BGN;
  };

  const totalPrice = getTotalPricePresentation();

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear()
    ].join('/');
  }

  var currentDate = formatDate(new Date());
  var paymentMethod = state.values.paymentType;
  var uuidOrder = state.values.uuidOrder;

  return (
    <ScrollToTop>
      <Helmet>
        <title> Поръчката е получена</title>
        <meta name="description" content="поръчката е получена" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container
        style={{ backgroundColor: '', paddingTop: 30, paddingBottom: 30, justify: 'center' }}>
        <FlexBox flexDirection="column" alignItems="center" justifyContent="center" mb={2}>
          {/* icon for order received */}
          <H3 sx={{ p: 2 }}>{strings[lang].thankYou}</H3>
          <H3 sx={{ paddingBottom: 2 }}>{strings[lang].yourOrderPlaced}</H3>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
            <Typography style={{ textAlign: 'center', paddingBottom: 10 }} color="primary.main">
              {strings[lang].reminderMessage1}
            </Typography>
            <Typography style={{ textAlign: 'center', paddingBottom: 20 }} color="primary.main">
              {strings[lang].reminderMessage2}
            </Typography>
            <Typography style={{ textAlign: 'center', paddingBottom: 20 }} color="primary.main">
              {strings[lang].reminderMessage3}
            </Typography>
          </div>

          {uuidOrder && (
            <H4 sx={{ p: 1 }}>
              {strings[lang].orderNumber}: {uuidOrder}
            </H4>
          )}
          {currentDate && (
            <H4 sx={{ p: 1 }}>
              {strings[lang].date}: {currentDate}
            </H4>
          )}
          {totalPrice && (
            <H4 sx={{ p: 1 }}>
              {' '}
              {strings[lang].total}: {totalPrice}
            </H4>
          )}
          {paymentMethod && (
            <H4 sx={{ p: 1 }}>
              {strings[lang].paymentMethodLabel}: {paymentMethod}
            </H4>
          )}
        </FlexBox>
        <Divider
          sx={{
            borderColor: 'grey.300',
            mb: '0.5rem',
            mt: '1em'
          }}
        />
        <Typography color="grey.800" fontSize="17px" sx={{ paddingBottom: 2 }}>
          {strings[lang].orderDetails}:
        </Typography>
        {state.values.cartList?.map((item) => (
          <FlexBox justifyContent="space-between" alignItems="center" mb={3} key={item.name}>
            <Image
              src={item.imgUrl || '/assets/images/products/iphone-xi.png'}
              height={140}
              width={140}
              display="block"
              alt={item.name}
            />

            <FlexBox
              className="product-details"
              flexDirection="column"
              justifyContent="space-between"
              minWidth="0px"
              width="100%"
              paddingLeft="10px">
              <Typography> {item.name} </Typography>

              {item.attributes &&
                Object.keys(item.attributes).map(function (attribute, index) {
                  return (
                    <Span color="grey.600" fontSize="14px" key={item.attributes[attribute].id}>
                      {item.attributes[attribute].label} : {item.attributes[attribute].value} cm
                    </Span>
                  );
                })}

              <FlexBox
                width="100%"
                justifyContent="space-between"
                alignItems="flex-end"
                paddingTop="5px">
                <FlexBox flexWrap="wrap" alignItems="center">
                  <Span color="grey.600" mr={1}>
                    {item.price} {currency.BGN} x {item.quantity}
                  </Span>
                  <Span fontWeight={600} color="primary.main" mr={2}>
                    {(item.price * item.quantity).toFixed(2)} {currency.BGN}
                  </Span>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        ))}
        <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="grey.600">{strings[lang].subtotal}:</Typography>
          <Typography fontWeight="500">{getSubTotalPricePresentation()}</Typography>
        </FlexBox>
        <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="grey.600">{strings[lang].shipping}:</Typography>
          <Typography fontWeight="500">
            {state.values.shippingType} - {state.values.shippingPrice} {currency.BGN}
          </Typography>
        </FlexBox>
        <FlexBox fontWeight="700" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography fontWeight="700">{strings[lang].total}:</Typography>
          <Typography fontWeight="900" fontSize="18px" color="primary.main">
            {totalPrice}
          </Typography>
        </FlexBox>
        {state.values.note && (
          <>
            <Typography color="grey.600">{strings[lang].note}:</Typography>
            <Typography fontSize="18px">{state.values.note}</Typography>
          </>
        )}
      </Container>
    </ScrollToTop>
  );
}

export default (state, action) => {
  switch (action.type) {
    default:
      return state;

    case 'LOAD_PRODUCT_LIST':
      return {
        ...state,
        productList: [...state.productList, ...action.payload]
      };

    case 'CLEAR_PRODUCT_LIST':
      return {
        ...state,
        productList: []
      };

    case 'LOAD_PRODUCT_SEARCH_LIST':
      return {
        ...state,
        searchList: [...state.searchList, ...action.payload]
      };

    case 'CLEAR_PRODUCT_SEARCH_LIST':
      return {
        ...state,
        searchList: []
      };

    case 'CLEAR_CART_LIST':
      return {
        ...state,
        cartList: []
      };

    case 'ADD_CART_ITEM':
      let cartLst = state.cartList;
      let newCartItm = action.payload[0];

      let existingItem = cartLst.find(
        (item) => item.id === newCartItm.id && item.attributes === newCartItm.attributes
      );
      if (!existingItem) {
        return {
          ...state,
          cartList: [...state.cartList, ...action.payload]
        };
      }
      return {
        ...state,
        cartList: cartLst.map((item) => {
          if (item.id === newCartItm.id && item.attributes === newCartItm.attributes)
            return { ...item, quantity: item.quantity + newCartItm.quantity };
          else return item;
        })
      };

    case 'REMOVE_CART_ITEM':
      return {
        ...state,
        cartList: state.cartList.filter((item) => item.id !== action.payload)
      };

    case 'SELECT_DELIVERY_OPTION':
      return {
        ...state,
        shippingOption: action.payload
      };

    case 'SET_CATEGORY_ID_URL_PARAM':
      return {
        ...state,
        categoryIdUrlParam: action.payload
      };

    case 'SET_SUBCATEGORY_ID_URL_PARAM':
      return {
        ...state,
        subcategoryIdUrlParam: action.payload
      };

    case 'SET_MATERIAL_ID_URL_PARAM':
      return {
        ...state,
        materialIdUrlParam: action.payload
      };

    case 'SET_PAGE_URL_PARAM': {
      return {
        ...state,
        pageUrlParam: action.payload
      };
    }

    case 'SET_SIZE_URL_PARAM': {
      return {
        ...state,
        sizeUrlParam: action.payload
      };
    }

    case 'SET_TERM_PARAM': {
      return {
        ...state,
        termParam: action.payload
      };
    }

    case 'SET_MENU': {
      let menuPayload = action.payload;
      for (var key in menuPayload) {
        menuPayload[key]['subCategories'].sort(function (a, b) {
          return a.position - b.position;
        });
      }
      return {
        ...state,
        menu: menuPayload
      };
    }

    case 'SET_MATERIAL_CATEGORIES': {
      return {
        ...state,
        materialCategories: action.payload
      };
    }

    case 'CHANGE_CART_ITEM_QTY':
      let cartList = state.cartList;
      let cartItem = action.payload;

      let exist = cartList.find(
        (item) => item.id === cartItem.id && item.attributes === action.payload.attributes
      );
      // only id check is not enought as product with the same id had multiple attributes so check other things too

      if (cartItem.quantity < 1 && exist) {
        return {
          ...state,
          cartList: cartList.filter((item) => item != exist)
        };
      } else if (exist)
        return {
          ...state,
          cartList: cartList.map((item) => {
            if (item.id === cartItem.id && item.attributes === action.payload.attributes)
              return { ...item, quantity: cartItem.quantity };
            else return item;
          })
        };
      else
        return {
          ...state,
          cartList: [...cartList, cartItem]
        };
  }
};

export const strings = {
  bg: {
    pageNotFound:
      'Моля потърсете желания продукт в търсачката или разгледайте селекцията от продукти в менюто.',
    requiredField: 'задължително',

    //OrderPlaced
    thankYou: 'БЛАГОДАРИМ ВИ!',
    yourOrderPlaced: 'ВАШАТА ПОРЪЧКА Е ПОЛУЧЕНА.',
    orderNumber: 'Номер на поръчката',
    date: 'Дата',
    total: 'Общо',
    subtotal: 'Междинна сума',
    paymentMethodLabel: 'Начин на плащане',
    orderDetails: 'Данни на поръчката',
    shipping: 'Доставка',
    reminderMessage1: 'НАПОМНЯМЕ, че:',
    reminderMessage2:
      'НЕ СЕ СВЪРЗВАМЕ с Вас, за потвърждение на Поръчката, освен в случаите, в които е възникнала необходимост от допълнителни уточнения. Правим го с цел да не Ви безпокоим с телефонни разговори или съобщения. Ако Вие решите, че имате въпроси относно Вашата поръчка, може да се свържете с нас по удобен за Вас начин и ние с радост ще Ви отговорим.',
    reminderMessage3:
      'ПРОДУКТИТЕ от Секция СПАЛНО БЕЛЬО не са готови на склад, а се изработват след получаване на Поръчката, което изисква технологично време. Предвидили сме срок от 3 до 15 работни дни, като се стремим да сме готови възможно най-бързо, без това да е за сметка на Качеството. Ако възникне причина, поради която няма да успеем да се справим в предвидения срок, ще се свържем с Вас, за да Ви предупредим и ако е необходимо да направим допълнителни уточнения.',

    //Footer
    footerInfo:
      'Домашен текстил. Спално бельо - Чаршафи, Пликове, Калъфки, Чаршафи с ластик, Кръгли Чаршафи с ластик, Бебешко и детско спално бельо. Индивидуални размери по поръчка. Високо качествени естествени текстилни материали.',
    footerInfo_next:
      'Домашен текстил. Спално бельо - Чаршафи, Пликове, Калъфки, Възглавници, завивки. Хавлии. Халати. Пижами - мъжки, дамски, детски. Нощници. Бельо - дамско, мъжко. Шалтета. Кувертюри. Протектори за матраци и за възглавници. Покривки за маса. Столовки.',
    infoTabLabel: 'Информация',
    helpTabLabel: 'Помощ',
    contactUsTabLabel: 'Cвържете се с нас',
    contactForm: 'Контактна форма',
    //footer pages
    returnPage: 'Връщане на закупена стока',
    deliveryPage: 'Доставка на стоките',
    paymentTypesPage: 'Начини за плащане',
    usefulInformationPage: 'Полезна информация',
    sizeTables: 'Таблици с размери',
    returnFormPage: 'Форма за Връщане / Анулиране / Замяна',
    privacyPage: 'Защита на личните данни',
    contactsPage: 'Контакти',
    termsAndConditionsPage: 'Общи условия',

    //layout
    searchBar: 'Какво търсите?',

    //Home
    categoriesTitle: 'Разгледай по Категория',
    categoriesSubTitle:
      'Продукти от високо качество, изработени от естествени материали и подбрани с внимание и грижа',
    categoryBedSheets: 'Чаршафи',
    categoryDuvetCovers: 'Пликове',
    categoryPillowcases: 'Калъфки',
    categoryFittedSheets: 'Чаршафи с ластик',
    categoryRoundFittedSheets: 'Кръргли Чаршафи с ластик',
    categoryKidsBedSheets: 'Детски Чаршафи',
    categoryKidsDuvetCovers: 'Детски Пликове',
    categoryKidsPillowcases: 'Детски Калъфки',
    categoryKidsFittedSheets: 'Детски Чаршафи с ластик',
    squareTitle1: 'Индивидуални размери',
    squareSubTitle1: 'За всички видове артикули за спално бельо', //Широка гама от размери на спално бельо
    squareTitle2: 'Лесна и бърза покупка',
    squareSubTitle2: 'Без регистрация и заплащане при доставка',
    squareTitle3: 'Произведено в България',
    squareSubTitle3: 'Естествени материали с доказано качество',
    squareTitle4: '30 дни право на връщане',
    squareSubTitle4: 'Гарантираме 100% възстановяване на средствата',

    //product list
    productCategories: 'Категории',
    loadMoreButton: 'ВИЖТЕ ОЩЕ',
    noSearchResults1: 'Не бяха намерени резултати от търсенето за ',
    noSearchResults2: 'Моля опитайте друго търсене.',
    noMoreProducts: 'Всички налични продукти от категорията са показани.',

    //product view
    description: 'Описание',

    //MiniCart
    emptyCartMessage: 'Количката Ви е празна.',
    product: 'продукт',
    products: 'продукта',
    viewCart: 'Вижте количката',
    addToCartButton: 'Добавете в количката',
    nitificationForAddedToCart: 'Добавено в количката',

    //Checkout
    yourOrderDetails: 'Вашата поръчка',

    //CheckoutForm
    personalDetails: 'Лични Данни',
    firstName: 'Име',
    surname: 'Фамилия',
    phoneNumber: 'Телефон',
    email: 'Имейл Адрес',
    deliveryAddress: 'Адрес за Доставка',
    deliveryNote:
      'Ако доставката е до ОФИС на ЕКОНТ/СПИДИ, моля напишете КОНКРЕТНИЯ ОФИС. Ако доставката е до ВАШ АДРЕС, моля напишете адреса.',
    streetAddress: 'Адрес',
    city: 'Град',
    county: 'Област',
    postcode: 'Пощенски код',
    country: 'Държава',
    bulgaria: 'България',
    note: 'Бележки към поръчката (по избор)',
    personalDetailsConfirmTnC:
      'Вашите лични данни ще бъдат използвани за обработка на вашата поръчка, както е описано в нашата Декларация за поверителност.',
    placeOrder: 'Направете Поръчка', //Поръчване
    checkoutNow: 'Към каса'
  },
  en: {},
  ru: {},
  default: {
    pageNotFound: '',

    requiredField: 'required',

    //OrderPlaced
    thankYou: '',
    yourOrderPlaced: '',
    orderNumber: '',
    date: '',
    total: '',
    subtotal: '',
    paymentMethodLabel: '',
    shipping: '',

    //Footer
    footerInfo: '',
    infoTabLabel: '',
    helpTabLabel: '',
    contactUsTabLabel: '',
    //footer pages
    returnPage: '',
    deliveryPage: '',
    paymentTypesPage: '',
    usefulInformationPage: '',
    sizeTables: '',
    returnFormPage: '',
    privacyPage: '',
    contactsPage: '',
    termsAndConditionsPage: '',

    //layout
    searchBar: '',

    //Home
    categoriesTitle: '',
    categoriesSubTitle: '',
    categoryBedSheets: '',
    categoryDuvetCovers: '',
    categoryPillowcases: '',
    categoryFittedSheets: '',
    categoryRoundFittedSheets: '',
    categoryKidsBedSheets: '',
    categoryKidsDuvetCovers: '',
    categoryKidsPillowcases: '',
    categoryKidsFittedSheets: '',
    squareTitle1: '',
    squareSubTitle1: '',
    squareTitle2: '',
    squareSubTitle2: '',
    squareTitle3: '',
    squareSubTitle3: '',
    squareTitle4: '',
    squareSubTitle4: '',

    //product list
    productCategories: '',
    loadMoreButton: '',
    noSearchResults1: '',
    noSearchResults2: '',
    noMoreProducts: 'No more products available in this category.',

    //product view
    description: 'Description',

    //MiniCart
    emptyCartMessage: '',
    product: '',
    products: '',
    viewCart: '',
    addToCartButton: '',
    nitificationForAddedToCart: '',

    //Checkout
    yourOrderDetails: '',

    //CheckoutForm
    personalDetails: '',
    firstName: '',
    surname: '',
    phoneNumber: '',
    email: '',
    deliveryAdress: '',
    deliveryNote: '',
    streetAddress: '',
    city: '',
    county: '',
    postcode: '',
    country: '',
    bulgaria: '',
    note: '',
    personalDetailsConfirmTnC: '',
    placeOrder: '',
    checkoutNow: ''
  }
};

import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import FlexBox from './FlexBox';
import BazarButton from './BazarButton';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Remove from '@mui/icons-material/Remove';

export default function IncDecButton({ counter, setCounter }) {
  return (
    <Box sx={{ m: 1 }}>
      <FlexBox alignItems="center" flexDirection="row">
        <BazarButton
          variant="outlined"
          color="primary"
          sx={{
            height: '32px',
            width: '32px',
            borderRadius: '300px'
          }}
          onClick={() => {
            setCounter(counter - 1);
          }}
          // onClick={handleCartAmountChange(item.quantity - 1, item)}
          disabled={counter === 1}>
          <Remove fontSize="small" />
        </BazarButton>
        <Box fontWeight={600} fontSize="20px" my="3px" padding="10px 20px">
          {counter}
        </Box>

        <BazarButton
          variant="outlined"
          color="primary"
          sx={{
            height: '32px',
            width: '32px',
            borderRadius: '300px'
          }}
          onClick={() => {
            setCounter(counter + 1);
          }}
          // onClick={handleCartAmountChange(item.quantity + 1, item)}
        >
          <Add fontSize="small" />
        </BazarButton>
      </FlexBox>
    </Box>
  );
}

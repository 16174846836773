import React, { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Image from './BazarImage';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { routerUrls } from 'constants';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useContext, useState } from 'react';
import { GlobalContext } from 'context/GlobalState';
import createGAevent from 'helpers';
import { currency, themeColors } from 'constants';
import fs from 'fs';

// TODO: LOSALISE COMPONENT
// GOTOBED PRODUCT COMPONENTS
const Product = ({ Product, OnSelect }) => {
  const params = useParams();
  const { lang } = useContext(GlobalContext);
  const [priceRange, setPriceRange] = useState([]);

  const fetchPriceRange = async (productId) => {
    try {
      params['productId'] = productId;
      const { data: response } = await axios.get(`/price/`, {
        params
      });

      let priceRange = [];
      let minPrice = null;
      let maxPrice = null;

      if (response && response.length > 1) {
        minPrice = Math.min.apply(
          null,
          response.map((obj) => parseFloat(obj.price_bgn))
        );
        priceRange.push(minPrice.toFixed(2));

        maxPrice = Math.max.apply(
          null,
          response.map((obj) => parseFloat(obj.price_bgn))
        );
        priceRange.push(maxPrice.toFixed(2));
        return priceRange;
      } else if (response.length === 1) {
        minPrice = parseFloat(response[0].price_bgn);
        priceRange.push(minPrice.toFixed(2));
        return priceRange;
      } else {
        return priceRange;
      }
    } catch (error) {
      let errorMessage = {
        error:
          '--------------------------------------------------------------------------' +
          new Date() +
          '-  Error while trying to fetchPriceRange!!!',
        error_log: error
      };
      fs.appendFile(
        'error_logs.json',
        JSON.stringify(errorMessage) +
          '--------------------------------------------------------------------------------------------------------------'
      );
    }
  };

  const getPriceRange = async (productId) => {
    const priceRangeData = await fetchPriceRange(productId);
    setPriceRange(priceRangeData);
  };

  useEffect(() => {
    getPriceRange(Product.id);
    createGAevent('ProductDetails', `PRODUCT_DETAILS-id-${Product.id}`, Product.id);
  }, []);

  return (
    //TODO: localise slug!!!!
    <Link
      to={routerUrls.product + '/' + Product.slug_bg + '/' + Product.id} //here we do the connection between ProductView and Product
      state={{ Product, priceRange }}
      //onClick={() => OnSelect(Product.id)} //<---- this breaks the state that we pass above!!!!
      style={{ color: 'inherit', textDecoration: 'none' }}>
      <Fragment>
        <Grid container style={{ backgroundColor: '' }}>
          <Grid item style={{ backgroundColor: '' }}>
            <Box
              posiiton="relative"
              style={{ width: '180', height: '240', backgroundColor: 'white' }}>
              <Image
                src={Product?.images?.length > 0 ? Product.images[0].url : 'some_default_img'}
                width={180}
                height={240}
                alt={Product.name_bg}
                style={{ backgroundColor: '' }}
                // for flip images on hover
                // onMouseOver={(e) => (e.currentTarget.src = Product.images[1].url)}
                // onMouseOut={(e) => (e.currentTarget.src = Product.images[0].url)}
              />
            </Box>

            {/* Title */}
            <Typography
              align="center"
              gutterBottom
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 4
              }}>
              {Product.name_bg}
            </Typography>

            {/* Price */}

            {/* {Product.price_range} */}
            {priceRange.length === 2 && (
              <Typography align="center" gutterBottom sx={{ color: themeColors.gunMetalGrey }}>
                {priceRange[0]} {currency.BGN} - {priceRange[1]} {currency.BGN}
              </Typography>
            )}

            {priceRange.length === 1 && (
              <Typography align="center" gutterBottom>
                {priceRange[0]} {currency.BGN}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Fragment>
    </Link>
  );
};

export default Product;

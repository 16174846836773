import ReactGA from 'react-ga4';

export default function createGAevent(category, action, label = null) {
  ReactGA.event({
    category: category,
    action: action,
    label: label // optional
    // value: value // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: 'xhr' // optional, beacon/xhr/image
  });
}

// TODO: https://www.freecodecamp.org/news/performance-and-user-tracking-in-react-with-google-analytics/
export function timeGA(category, variable, value) {
  ReactGA.timing({
    category: category, // 'Load Performace',
    variable: variable, // 'Some metric',
    value: value // 'Value of Metric'
  });
}

//TODO: send e-commerce data: https://stackoverflow.com/questions/62086820/impossible-to-send-ecommerce-data-to-google-analytics-with-react

//TODO: identify different users/ Potentially based on API

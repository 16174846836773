import Image from './BazarImage';
import FlexBox from './FlexBox';
import MiniCart from './MiniCart';
import { Badge, Box, Container, Drawer, IconButton, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { assets } from 'constants';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Link from '@mui/material/Link';
import React, { useState, useContext } from 'react';
import SearchBox from './SearchBox';
import { GlobalContext } from '../context/GlobalState';
import MobileNavBar from './MobileNavBar';

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: 10,
  borderBottom: '1px solid lightgray',
  zIndex: 1,
  background: theme.palette.background.paper,
  transition: 'height 250ms ease-in-out',
  marginBottom: 10
}));

//GOTOBED HEADER COMPONENTS
export default function Header() {
  const [sidenavOpen, setSidenavOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const toggleSidenav = () => setSidenavOpen(!sidenavOpen);
  const { cartList } = useContext(GlobalContext);

  const cartHandle = (
    <Badge badgeContent={cartList.length} color="primary">
      <Box component={IconButton} ml={2.5} bgcolor="grey.200" p={1.25} onClick={toggleSidenav}>
        <ShoppingCartIcon />
      </Box>
    </Badge>
  );

  return (
    <HeaderWrapper>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
          paddingBottom: 1,
          backgroundColor: ''
        }}>
        {isMobile && (
          <>
            <MobileNavBar />
          </>
        )}
        {/* Logo Box */}
        <FlexBox
          alignItems="center"
          mr={2}
          minWidth="170px"
          sx={{ display: { xs: 'flex', md: 'flex' } }}>
          <Link href="/">
            <Image height={58} width={183} src={assets.logo} alt="logo" />
          </Link>
        </FlexBox>

        <FlexBox
          justifyContent="center"
          minWidth="500px"
          sx={{
            display: {
              xs: 'none',
              md: 'flex'
            }
          }}>
          <SearchBox />
        </FlexBox>

        <FlexBox
          alignItems="center"
          sx={{
            display: {
              xs: 'flex',
              md: 'flex'
            }
          }}>
          {cartHandle}
        </FlexBox>

        <Drawer
          open={sidenavOpen}
          anchor="right"
          onClose={toggleSidenav}
          sx={{
            display: 'flex'
          }}>
          <MiniCart toggleSidenav={toggleSidenav} />
        </Drawer>
      </Container>
      {isMobile && <SearchBox />}
    </HeaderWrapper>
  );
}

import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';

export default function PaymentRadioButtons({ resultSelected, setResultSelected, paymentOptions }) {
  const handleValueChange = (event) => {
    let selectedValue = event.target.value;
    setResultSelected(selectedValue);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={resultSelected}
        onChange={handleValueChange}>
        {paymentOptions.map((item) => (
          <>
            <FormControlLabel value={item.name} control={<Radio />} label={item.name} />
            {resultSelected === item.name && (
              <Typography style={{ paddingLeft: 30, color: 'grey' }}>{item.description}</Typography>
            )}
          </>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

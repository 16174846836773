import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { themeColors } from 'constants';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';
import { languages, currency } from 'constants';
import { Helmet } from 'react-helmet';

const meta_tags = {
  // bed linen - bed sheet
  '1-1': [
    '110х210',
    '110х220',
    '110х240',
    '120х210',
    '120х220',
    '120х240',
    '130х210',
    '130х220',
    '130х240',
    '140х210',
    '140х220',
    '140х240',
    '150х210',
    '150х220',
    '150х240',
    '160х210',
    '160х220',
    '160х240',
    '160х280',
    '170х210',
    '170х220',
    '170х240',
    '170х280',
    '180х210',
    '180х220',
    '180х240',
    '180х280',
    '190х210',
    '190х220',
    '190х240',
    '190х280',
    '200х210',
    '200х220',
    '200х240',
    '200х280',
    '200х300',
    '210х210',
    '210х220',
    '210х240',
    '210х280',
    '210х300',
    '220х210',
    '220х220',
    '220х240',
    '220х280',
    '220х300',
    '230х210',
    '230х220',
    '230х240',
    '230х280',
    '230х300',
    '240х210',
    '240х220',
    '240х240',
    '240х280',
    '240х300',
    '250х240',
    '250х280',
    '250х320',
    '260х240',
    '260х280',
    '260х320',
    '270х240',
    '270х280',
    '270х320',
    '280х240',
    '280х280',
    '280х320',
    '290х240',
    '290х280',
    '290х320',
    '300х280',
    '300х320',
    '300х360',
    '320х280',
    '320х320',
    '320х360',
    '340х280',
    '340х320',
    '340х360',
    '360х280',
    '360х320',
    '360х360',
    '210х110',
    '220х110',
    '240х110',
    '210х120',
    '220х120',
    '240х120',
    '210х130',
    '220х130',
    '240х130',
    '210х140',
    '220х140',
    '240х140',
    '210х150',
    '220х150',
    '240х150',
    '210х160',
    '220х160',
    '240х160',
    '280х160',
    '210х170',
    '220х170',
    '240х170',
    '280х170',
    '210х180',
    '220х180',
    '240х180',
    '280х180',
    '210х190',
    '220х190',
    '240х190',
    '280х190',
    '210х200',
    '220х200',
    '240х200',
    '280х200',
    '300х200',
    '210х210',
    '220х210',
    '240х210',
    '280х210',
    '300х210',
    '210х220',
    '220х220',
    '240х220',
    '280х220',
    '300х220',
    '210х230',
    '220х230',
    '240х230',
    '280х230',
    '300х230',
    '210х240',
    '220х240',
    '240х240',
    '280х240',
    '300х240',
    '240х250',
    '280х250',
    '320х250',
    '240х260',
    '280х260',
    '320х260',
    '240х270',
    '280х270',
    '320х270',
    '240х280',
    '280х280',
    '320х280',
    '240х290',
    '280х290',
    '320х290',
    '280х300',
    '320х300',
    '360х300',
    '280х320',
    '320х320',
    '360х320',
    '280х340',
    '320х340',
    '360х340',
    '280х360',
    '320х360',
    '360х360',
    '110/210',
    '110/220',
    '110/240',
    '120/210',
    '120/220',
    '120/240',
    '130/210',
    '130/220',
    '130/240',
    '140/210',
    '140/220',
    '140/240',
    '150/210',
    '150/220',
    '150/240',
    '160/210',
    '160/220',
    '160/240',
    '160/280',
    '170/210',
    '170/220',
    '170/240',
    '170/280',
    '180/210',
    '180/220',
    '180/240',
    '180/280',
    '190/210',
    '190/220',
    '190/240',
    '190/280',
    '200/210',
    '200/220',
    '200/240',
    '200/280',
    '200/300',
    '210/210',
    '210/220',
    '210/240',
    '210/280',
    '210/300',
    '220/210',
    '220/220',
    '220/240',
    '220/280',
    '220/300',
    '230/210',
    '230/220',
    '230/240',
    '230/280',
    '230/300',
    '240/210',
    '240/220',
    '240/240',
    '240/280',
    '240/300',
    '250/240',
    '250/280',
    '250/320',
    '260/240',
    '260/280',
    '260/320',
    '270/240',
    '270/280',
    '270/320',
    '280/240',
    '280/280',
    '280/320',
    '290/240',
    '290/280',
    '290/320',
    '300/280',
    '300/320',
    '300/360',
    '320/280',
    '320/320',
    '320/360',
    '340/280',
    '340/320',
    '340/360',
    '360/280',
    '360/320',
    '360/360',
    '210/110',
    '220/110',
    '240/110',
    '210/120',
    '220/120',
    '240/120',
    '210/130',
    '220/130',
    '240/130',
    '210/140',
    '220/140',
    '240/140',
    '210/150',
    '220/150',
    '240/150',
    '210/160',
    '220/160',
    '240/160',
    '280/160',
    '210/170',
    '220/170',
    '240/170',
    '280/170',
    '210/180',
    '220/180',
    '240/180',
    '280/180',
    '210/190',
    '220/190',
    '240/190',
    '280/190',
    '210/200',
    '220/200',
    '240/200',
    '280/200',
    '300/200',
    '210/210',
    '220/210',
    '240/210',
    '280/210',
    '300/210',
    '210/220',
    '220/220',
    '240/220',
    '280/220',
    '300/220',
    '210/230',
    '220/230',
    '240/230',
    '280/230',
    '300/230',
    '210/240',
    '220/240',
    '240/240',
    '280/240',
    '300/240',
    '240/250',
    '280/250',
    '320/250',
    '240/260',
    '280/260',
    '320/260',
    '240/270',
    '280/270',
    '320/270',
    '240/280',
    '280/280',
    '320/280',
    '240/290',
    '280/290',
    '320/290',
    '280/300',
    '320/300',
    '360/300',
    '280/320',
    '320/320',
    '360/320',
    '280/340',
    '320/340',
    '360/340',
    '280/360',
    '320/360',
    '360/360'
  ],

  // bed linen - pillowcase
  '1-2': [
    '50х70',
    '60х70',
    '60х80',
    '60х90',
    '60х100',
    '40х60',
    '40х50',
    '48х68',
    '35х53',
    '33х66',
    '70х50',
    '70х60',
    '80х60',
    '90х60',
    '100х60',
    '60х40',
    '50х40',
    '68х48',
    '53х35',
    '66х33',
    '50x70',
    '60x70',
    '60x80',
    '60x90',
    '60x100',
    '40x60',
    '40x50',
    '48x68',
    '35x53',
    '33x66',
    '70x50',
    '70x60',
    '80x60',
    '90x60',
    '100x60',
    '60x40',
    '50x40',
    '68x48',
    '53x35',
    '66x33',
    '50/70',
    '60/70',
    '60/80',
    '60/90',
    '60/100',
    '40/60',
    '40/50',
    '48/68',
    '35/53',
    '33/66',
    '70/50',
    '70/60',
    '80/60',
    '90/60',
    '100/60',
    '60/40',
    '50/40',
    '68/48',
    '53/35',
    '66/33'
  ],

  // bed linen - fitted bed sheet
  '1-3': [
    '80/190/15',
    '80/200/15',
    '80/210/15',
    '80/220/15',
    '80/190/20',
    '80/200/20',
    '80/210/20',
    '80/220/20',
    '80/190/25',
    '80/200/25',
    '80/210/25',
    '80/220/25',
    '80/190/30',
    '80/200/30',
    '80/210/30',
    '80/220/30',
    '80/190/40',
    '80/200/40',
    '80/210/40',
    '80/220/40',
    '80/190/50',
    '80/200/50',
    '80/210/50',
    '80/220/50',
    '80х190х15',
    '80х200х15',
    '80х210х15',
    '80х220х15',
    '80х190х20',
    '80х200х20',
    '80х210х20',
    '80х220х20',
    '80х190х25',
    '80х200х25',
    '80х210х25',
    '80х220х25',
    '80х190х30',
    '80х200х30',
    '80х210х30',
    '80х220х30',
    '80х190х40',
    '80х200х40',
    '80х210х40',
    '80х220х40',
    '80х190х50',
    '80х200х50',
    '80х210х50',
    '80х220х50',
    '164/190/15',
    '164/200/15',
    '164/210/15',
    '164/220/15',
    '164/190/20',
    '164/200/20',
    '164/210/20',
    '164/220/20',
    '164/190/25',
    '164/200/25',
    '164/210/25',
    '164/220/25',
    '164/190/30',
    '164/200/30',
    '164/210/30',
    '164/220/30',
    '164/190/40',
    '164/200/40',
    '164/210/40',
    '164/220/40',
    '164/190/50',
    '164/200/50',
    '164/210/50',
    '164/220/50',
    '164х190х15',
    '164х200х15',
    '164х210х15',
    '164х220х15',
    '164х190х20',
    '164х200х20',
    '164х210х20',
    '164х220х20',
    '164х190х25',
    '164х200х25',
    '164х210х25',
    '164х220х25',
    '164х190х30',
    '164х200х30',
    '164х210х30',
    '164х220х30',
    '164х190х40',
    '164х200х40',
    '164х210х40',
    '164х220х40',
    '164х190х50',
    '164х200х50',
    '164х210х50',
    '164х220х50',
    '220/190/15',
    '220/200/15',
    '220/210/15',
    '220/220/15',
    '220/190/20',
    '220/200/20',
    '220/210/20',
    '220/220/20',
    '220/190/25',
    '220/200/25',
    '220/210/25',
    '220/220/25',
    '220/190/30',
    '220/200/30',
    '220/210/30',
    '220/220/30',
    '220/190/40',
    '220/200/40',
    '220/210/40',
    '220/220/40',
    '220/190/50',
    '220/200/50',
    '220/210/50',
    '220/220/50',
    '220х190х15',
    '220х200х15',
    '220х210х15',
    '220х220х15',
    '220х190х20',
    '220х200х20',
    '220х210х20',
    '220х220х20',
    '220х190х25',
    '220х200х25',
    '220х210х25',
    '220х220х25',
    '220х190х30',
    '220х200х30',
    '220х210х30',
    '220х220х30',
    '220х190х40',
    '220х200х40',
    '220х210х40',
    '220х220х40',
    '220х190х50',
    '220х200х50',
    '220х210х50',
    '220х220х50',
    '200/190/15',
    '200/200/15',
    '200/210/15',
    '200/220/15',
    '200/190/20',
    '200/200/20',
    '200/210/20',
    '200/220/20',
    '200/190/25',
    '200/200/25',
    '200/210/25',
    '200/220/25',
    '200/190/30',
    '200/200/30',
    '200/210/30',
    '200/220/30',
    '200/190/40',
    '200/200/40',
    '200/210/40',
    '200/220/40',
    '200/190/50',
    '200/200/50',
    '200/210/50',
    '200/220/50',
    '200х190х15',
    '200х200х15',
    '200х210х15',
    '200х220х15',
    '200х190х20',
    '200х200х20',
    '200х210х20',
    '200х220х20',
    '200х190х25',
    '200х200х25',
    '200х210х25',
    '200х220х25',
    '200х190х30',
    '200х200х30',
    '200х210х30',
    '200х220х30',
    '200х190х40',
    '200х200х40',
    '200х210х40',
    '200х220х40',
    '200х190х50',
    '200х200х50',
    '200х210х50',
    '200х220х50',
    '180/190/15',
    '180/200/15',
    '180/210/15',
    '180/220/15',
    '180/190/20',
    '180/200/20',
    '180/210/20',
    '180/220/20',
    '180/190/25',
    '180/200/25',
    '180/210/25',
    '180/220/25',
    '180/190/30',
    '180/200/30',
    '180/210/30',
    '180/220/30',
    '180/190/40',
    '180/200/40',
    '180/210/40',
    '180/220/40',
    '180/190/50',
    '180/200/50',
    '180/210/50',
    '180/220/50',
    '180х190х15',
    '180х200х15',
    '180х210х15',
    '180х220х15',
    '180х190х20',
    '180х200х20',
    '180х210х20',
    '180х220х20',
    '180х190х25',
    '180х200х25',
    '180х210х25',
    '180х220х25',
    '180х190х30',
    '180х200х30',
    '180х210х30',
    '180х220х30',
    '180х190х40',
    '180х200х40',
    '180х210х40',
    '180х220х40',
    '180х190х50',
    '180х200х50',
    '180х210х50',
    '180х220х50',
    '115/190/15',
    '115/200/15',
    '115/210/15',
    '115/220/15',
    '115/190/20',
    '115/200/20',
    '115/210/20',
    '115/220/20',
    '115/190/25',
    '115/200/25',
    '115/210/25',
    '115/220/25',
    '115/190/30',
    '115/200/30',
    '115/210/30',
    '115/220/30',
    '115/190/40',
    '115/200/40',
    '115/210/40',
    '115/220/40',
    '115/190/50',
    '115/200/50',
    '115/210/50',
    '115/220/50',
    '115х190х15',
    '115х200х15',
    '115х210х15',
    '115х220х15',
    '115х190х20',
    '115х200х20',
    '115х210х20',
    '115х220х20',
    '115х190х25',
    '115х200х25',
    '115х210х25',
    '115х220х25',
    '115х190х30',
    '115х200х30',
    '115х210х30',
    '115х220х30',
    '115х190х40',
    '115х200х40',
    '115х210х40',
    '115х220х40',
    '115х190х50',
    '115х200х50',
    '115х210х50',
    '115х220х50',
    '135/190/15',
    '135/200/15',
    '135/210/15',
    '135/220/15',
    '135/190/20',
    '135/200/20',
    '135/210/20',
    '135/220/20',
    '135/190/25',
    '135/200/25',
    '135/210/25',
    '135/220/25',
    '135/190/30',
    '135/200/30',
    '135/210/30',
    '135/220/30',
    '135/190/40',
    '135/200/40',
    '135/210/40',
    '135/220/40',
    '135/190/50',
    '135/200/50',
    '135/210/50',
    '135/220/50',
    '135х190х15',
    '135х200х15',
    '135х210х15',
    '135х220х15',
    '135х190х20',
    '135х200х20',
    '135х210х20',
    '135х220х20',
    '135х190х25',
    '135х200х25',
    '135х210х25',
    '135х220х25',
    '135х190х30',
    '135х200х30',
    '135х210х30',
    '135х220х30',
    '135х190х40',
    '135х200х40',
    '135х210х40',
    '135х220х40',
    '135х190х50',
    '135х200х50',
    '135х210х50',
    '135х220х50',
    '100/190/15',
    '100/200/15',
    '100/210/15',
    '100/220/15',
    '100/190/20',
    '100/200/20',
    '100/210/20',
    '100/220/20',
    '100/190/25',
    '100/200/25',
    '100/210/25',
    '100/220/25',
    '100/190/30',
    '100/200/30',
    '100/210/30',
    '100/220/30',
    '100/190/40',
    '100/200/40',
    '100/210/40',
    '100/220/40',
    '100/190/50',
    '100/200/50',
    '100/210/50',
    '100/220/50',
    '100х190х15',
    '100х200х15',
    '100х210х15',
    '100х220х15',
    '100х190х20',
    '100х200х20',
    '100х210х20',
    '100х220х20',
    '100х190х25',
    '100х200х25',
    '100х210х25',
    '100х220х25',
    '100х190х30',
    '100х200х30',
    '100х210х30',
    '100х220х30',
    '100х190х40',
    '100х200х40',
    '100х210х40',
    '100х220х40',
    '100х190х50',
    '100х200х50',
    '100х210х50',
    '100х220х50'
  ],

  // bed linen - duvet cover
  '1-4': [
    '120х190',
    '120х200',
    '120х210',
    '120х220',
    '130х190',
    '130х200',
    '130х210',
    '130х220',
    '130х230',
    '140х190',
    '140х200',
    '140х210',
    '140х220',
    '140х230',
    '150х190',
    '150х200',
    '150х210',
    '150х220',
    '150х230',
    '160х200',
    '160х210',
    '160х220',
    '160х230',
    '170х200',
    '170х210',
    '170х220',
    '170х230',
    '190х120',
    '200х120',
    '210х120',
    '220х120',
    '190х130',
    '200х130',
    '210х130',
    '220х130',
    '230х130',
    '190х140',
    '200х140',
    '210х140',
    '220х140',
    '230х140',
    '190х150',
    '200х150',
    '210х150',
    '220х150',
    '230х150',
    '200х160',
    '210х160',
    '220х160',
    '230х160',
    '200х170',
    '210х170',
    '220х170',
    '230х170',
    '120/190',
    '120/200',
    '120/210',
    '120/220',
    '130/190',
    '130/200',
    '130/210',
    '130/220',
    '130/230',
    '140/190',
    '140/200',
    '140/210',
    '140/220',
    '140/230',
    '150/190',
    '150/200',
    '150/210',
    '150/220',
    '150/230',
    '160/200',
    '160/210',
    '160/220',
    '160/230',
    '170/200',
    '170/210',
    '170/220',
    '170/230',
    '190/120',
    '200/120',
    '210/120',
    '220/120',
    '190/130',
    '200/130',
    '210/130',
    '220/130',
    '230/130',
    '190/140',
    '200/140',
    '210/140',
    '220/140',
    '230/140',
    '190/150',
    '200/150',
    '210/150',
    '220/150',
    '230/150',
    '200/160',
    '210/160',
    '220/160',
    '230/160',
    '200/170',
    '210/170',
    '220/170',
    '230/170'
  ],

  // bed linen - round bed sheet
  '1-6': [
    '200х10',
    '200х15',
    '200х16',
    '200х20',
    '200х25',
    '200х26',
    '200х30',
    '200х35',
    '200х40',
    '200х45',
    '200х50',
    '200/10',
    '200/15',
    '200/16',
    '200/20',
    '200/25',
    '200/26',
    '200/30',
    '200/35',
    '200/40',
    '200/45',
    '200/50',
    '210х10',
    '210х15',
    '210х16',
    '210х20',
    '210х25',
    '210х26',
    '210х30',
    '210х35',
    '210х40',
    '210х45',
    '210х50',
    '210/10',
    '210/15',
    '210/16',
    '210/20',
    '210/25',
    '210/26',
    '210/30',
    '210/35',
    '210/40',
    '210/45',
    '210/50',
    '220х10',
    '220х15',
    '220х16',
    '220х20',
    '220х25',
    '220х26',
    '220х30',
    '220х35',
    '220х40',
    '220х45',
    '220х50',
    '220/10',
    '220/15',
    '220/16',
    '220/20',
    '220/25',
    '220/26',
    '220/30',
    '220/35',
    '220/40',
    '220/45',
    '220/50'
  ],

  // kids bed linen - bed sheet
  '2-7': [
    '115х155',
    '115х180',
    '120х200',
    '130х220',
    '150х220',
    '160х240',
    '180х240',
    '200х240',
    '220х240',
    '155х115',
    '180х115',
    '200х120',
    '220х130',
    '220х150',
    '240х160',
    '240х180',
    '240х200',
    '240х220',
    '115/155',
    '115/180',
    '120/200',
    '130/220',
    '150/220',
    '160/240',
    '180/240',
    '200/240',
    '220/240',
    '155/115',
    '180/115',
    '200/120',
    '220/130',
    '220/150',
    '240/160',
    '240/180',
    '240/200',
    '240/220'
  ],

  // kids bed linen - pillowcase
  '2-8': [
    '50х70',
    '48х68',
    '43х63',
    '40х60',
    '40х50',
    '33х66',
    '37х47',
    '35х53',
    '33х43',
    '30х40',
    '28х35',
    '70х70',
    '60х60',
    '70х50',
    '68х48',
    '63х43',
    '60х40',
    '50х40',
    '66х33',
    '47х37',
    '53х35',
    '43х33',
    '40х30',
    '35х28',
    '70х70',
    '60х60',
    '50/70',
    '48/68',
    '43/63',
    '40/60',
    '40/50',
    '33/66',
    '37/47',
    '35/53',
    '33/43',
    '30/40',
    '28/35',
    '70/70',
    '60/60',
    '70/50',
    '68/48',
    '63/43',
    '60/40',
    '50/40',
    '66/33',
    '47/37',
    '53/35',
    '43/33',
    '40/30',
    '35/28',
    '70/70',
    '60/60',
    '50х70',
    '48х68',
    '43х63',
    '40х60',
    '40х50',
    '33х66',
    '37х47',
    '35х53',
    '33х43',
    '30х40',
    '28х35',
    '70х70',
    '60х60',
    '70х50',
    '68х48',
    '63х43',
    '60х40',
    '50х40',
    '66х33',
    '47х37',
    '53х35',
    '43х33',
    '40х30',
    '35х28',
    '70х70',
    '60х60'
  ],

  // kids bed linen - fitted bed sheet
  '2-9': [
    '60х110х20',
    '60х120х20',
    '60х130х20',
    '60х140х20',
    '65х110х20',
    '65х120х20',
    '65х130х20',
    '65х140х20',
    '65х150х20',
    '65х160х20',
    '70х110х20',
    '70х120х20',
    '70х130х20',
    '70х140х20',
    '70х150х20',
    '70х160х20',
    '70х170х20',
    '70х180х20',
    '70х190х20',
    '75х140х20',
    '75х150х20',
    '75х160х20',
    '75х170х20',
    '75х180х20',
    '75х190х20',
    '82х180х20',
    '82х190х20',
    '82х200х20',
    '82х200х30',
    '90х190х20',
    '90х190х30',
    '90х200х20',
    '90х200х30',
    '60/110/20',
    '60/120/20',
    '60/130/20',
    '60/140/20',
    '65/110/20',
    '65/120/20',
    '65/130/20',
    '65/140/20',
    '65/150/20',
    '65/160/20',
    '70/110/20',
    '70/120/20',
    '70/130/20',
    '70/140/20',
    '70/150/20',
    '70/160/20',
    '70/170/20',
    '70/180/20',
    '70/190/20',
    '75/140/20',
    '75/150/20',
    '75/160/20',
    '75/170/20',
    '75/180/20',
    '75/190/20',
    '82/180/20',
    '82/190/20',
    '82/200/20',
    '82/200/30',
    '90/190/20',
    '90/190/30',
    '90/200/20',
    '90/200/30'
  ],

  // kids bed linen - duvet cover
  '2-10': [
    '103х130',
    '105х140',
    '115х150',
    '115х160',
    '130х200',
    '140х200',
    '140х210',
    '150х200',
    '150х210',
    '150х220',
    '160х200',
    '160х210',
    '160х220',
    '180х220',
    '200х200',
    '200х220',
    '130х103',
    '140х105',
    '150х115',
    '160х115',
    '200х130',
    '200х140',
    '210х140',
    '200х150',
    '210х150',
    '220х150',
    '200х160',
    '210х160',
    '220х160',
    '220х180',
    '200х200',
    '220х200',
    '103/130',
    '105/140',
    '115/150',
    '115/160',
    '130/200',
    '140/200',
    '140/210',
    '150/200',
    '150/210',
    '150/220',
    '160/200',
    '160/210',
    '160/220',
    '180/220',
    '200/200',
    '200/220',
    '130/103',
    '140/105',
    '150/115',
    '160/115',
    '200/130',
    '200/140',
    '210/140',
    '200/150',
    '210/150',
    '220/150',
    '200/160',
    '210/160',
    '220/160',
    '220/180',
    '200/200',
    '220/200'
  ]
};

export default function ProductViewGridItemIntro({
  product,
  priceRange,
  additionalProductData,
  variationPrice
}) {
  const { lang } = useContext(GlobalContext);
  const [summary, setSummary] = useState([]);
  const [images, setImages] = useState([]);

  React.useEffect(() => {
    if (product && product.images) {
      const temp = product['images'].sort(function (a, b) {
        return a['Product_Image'].position - b['Product_Image'].position;
      });
      setImages(temp);
    }
    if (
      additionalProductData &&
      languages.bg === lang &&
      'summary_bg' in additionalProductData &&
      typeof additionalProductData.summary_bg !== 'undefined' &&
      additionalProductData.summary_bg != null
    ) {
      let summary_result = additionalProductData.summary_bg.split(';').map(function (item) {
        return item.trim();
      });
      summary_result = summary_result.filter((element) => {
        return element !== '';
      });
      setSummary(summary_result);
    }
  }, [additionalProductData]);

  const tag_key = `${product.categoryId}-${product.subCategoryId}`;

  const getRandomStrings = (array, count = 10) => {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  return (
    <>
      <Helmet>
        <title>{product.name_bg}</title>
        <meta name="description" content={getRandomStrings(meta_tags[tag_key])} />
        <link rel="canonical" href={window.location.href} />

        <meta property="og:title" content={product.name_bg} />
        <meta property="og:description" content={summary} />
        {images && images.length > 0 && <meta property="og:image" content={images[0].url} />}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="GoToBed" />
      </Helmet>
      <Grid item xs={12} sm={5} style={{ backgroundColor: '' }}>
        <Typography
          style={{ wordWrap: 'break-word', fontWeight: '600' }}
          sx={{ p: 1 }}
          variant="h6">
          {languages.bg === lang && product.name_bg}
        </Typography>

        {/* {Product.price_range} */}
        {priceRange && priceRange.length === 2 && (
          <Typography
            sx={{ p: 1, fontSize: '20px', fontWeight: '600', color: themeColors.cherryRedDeep }}>
            {priceRange[0]} {currency.BGN} - {priceRange[1]} {currency.BGN}
          </Typography>
        )}

        {variationPrice && (
          <Typography
            sx={{ p: 1, paddingTop: 0, fontSize: '20px', fontWeight: '700', color: 'green' }}>
            {variationPrice} {currency.BGN}
          </Typography>
        )}

        {priceRange && priceRange.length === 1 && (
          <Typography
            sx={{ p: 1, fontSize: '20px', fontWeight: '600', color: themeColors.cherryRedDeep }}>
            {priceRange[0]} {currency.BGN}
          </Typography>
        )}
        <Divider />

        <div
          style={{
            paddingTop: '10px',
            fontWeight: '600',
            paddingLeft: '10px',
            paddingBottom: '10px'
          }}>
          {summary[0]}
        </div>

        <List sx={{ listStyleType: 'disc', pl: '30px' }} disablePadding>
          {summary?.map((summary, idx) => (
            <div key={idx}>
              {idx != 0 && (
                <ListItem
                  key={idx}
                  disableGutters
                  sx={{
                    display: 'list-item'
                  }}>
                  {summary}
                </ListItem>
              )}
            </div>
          ))}
        </List>
      </Grid>
    </>
  );
}

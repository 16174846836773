import Image from './BazarImage';
import { Paragraph } from './Typography';
import { Box, Container, Grid, styled, Typography } from '@mui/material';
import { assets, stringConstants, routerUrls } from 'constants';
import { Link } from 'react-router-dom';
import React from 'react';
import FlexBox from './FlexBox';
import { themeColors } from 'constants';
import { useContext } from 'react';
import { GlobalContext } from 'context/GlobalState';
import { strings } from 'localisation';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledLink = styled('a')(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: '0.3rem 0rem',
  color: theme.palette.grey[500],
  cursor: 'pointer',
  borderRadius: 4,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.grey[100]
  }
}));

const FooterBazar = () => {
  const { lang } = useContext(GlobalContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const aboutLinks = [
    {
      pageName: strings[lang].returnPage,
      pageLink: routerUrls.returnInfo
    },
    {
      pageName: strings[lang].deliveryPage,
      pageLink: routerUrls.deliveryInfo
    },
    {
      pageName: strings[lang].paymentTypesPage,
      pageLink: routerUrls.paymentMethods
    }
  ];

  const customerCareLinks = [
    {
      pageName: strings[lang].usefulInformationPage,
      pageLink: routerUrls.usefulInfo
    },
    {
      pageName: strings[lang].sizeTables,
      pageLink: routerUrls.sizingGuides
    },
    {
      pageName: strings[lang].returnFormPage,
      pageLink: routerUrls.returnForm
    },
    {
      pageName: strings[lang].privacyPage,
      pageLink: routerUrls.privacyPolicy
    },
    {
      pageName: strings[lang].contactsPage,
      pageLink: routerUrls.contacts
    },
    {
      pageName: strings[lang].termsAndConditionsPage,
      pageLink: routerUrls.termsAndConditions
    }
  ];
  return (
    <footer>
      <Box bgcolor={themeColors.darkBlue} sx={{ mt: 5 }}>
        <Container
          sx={{
            p: '1rem',
            color: 'white'
          }}>
          <Box py={isMobile ? 3 : 10} overflow="hidden">
            <Grid container spacing={3}>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <Image height={58} width={183} mb={2.5} src={assets.logoWhite} alt="logo" />
                <Paragraph mb={2.5} color="grey.500" style={{ fontSize: 15 }}>
                  {strings[lang].footerInfo}
                </Paragraph>
              </Grid>

              <Grid item lg={2} md={6} sm={6} xs={12}>
                <Box fontSize="25px" fontWeight="600" mb={2.5} lineheight="1" color="white">
                  {strings[lang].infoTabLabel}
                </Box>

                <>
                  {aboutLinks.map((item, idx) => (
                    <Link
                      to={item.pageLink}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                      key={idx}>
                      <StyledLink>
                        <Typography>{item.pageName}</Typography>
                      </StyledLink>
                    </Link>
                  ))}
                </>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box fontSize="25px" fontWeight="600" mb={2.5} lineheight="1" color="white">
                  {strings[lang].helpTabLabel}
                </Box>

                <>
                  {customerCareLinks.map((item, idx) => (
                    <Link
                      to={item.pageLink}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                      key={idx}>
                      <StyledLink>
                        <Typography>{item.pageName}</Typography>
                      </StyledLink>
                    </Link>
                  ))}
                </>
              </Grid>

              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Box fontSize="25px" fontWeight="600" mb={2.5} lineheight="1" color="white">
                  {strings[lang].contactUsTabLabel}
                </Box>
                <Link
                  to={routerUrls.contactForm}
                  style={{ color: 'inherit', textDecoration: 'none' }}>
                  <Typography py={0.6}>{strings[lang].contactForm}</Typography>
                </Link>
                <Box py={0.6} color="grey.500">
                  Email: {stringConstants.email}
                </Box>
                <Box py={0.6} mb={2} color="grey.500">
                  WhatsApp/Viber: {stringConstants.phone}
                </Box>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Box py={0} mb={2} color="grey.500">
                    <a href="https://www.facebook.com/gotobed.eu" target="_blank" rel="noreferrer">
                      <FacebookIcon style={{ color: 'darkgrey', width: '25px' }} />
                    </a>
                  </Box>

                  <Box py={0} px={1} mb={2} color="grey.500">
                    <a
                      href="https://www.instagram.com/gotobed.eu/"
                      target="_blank"
                      rel="noreferrer">
                      <InstagramIcon style={{ color: 'darkgrey', width: '25px' }} />
                    </a>
                  </Box>
                </div>

                <FlexBox className="flex" mx={-0.625}></FlexBox>
              </Grid>
            </Grid>
          </Box>
          <Typography align="center" sx={{ color: 'lightgray', fontSize: '14px' }}>
            Copyright © GoToBed 2024. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </footer>
  );
};

export default FooterBazar;

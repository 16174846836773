import BazarCard from './BazarCard';
import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { H3, Span } from './Typography';
import appIcons from 'icons';
import { themeColors } from 'constants';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const HomeSectionPromo = ({ serviceList }) => {
  return (
    <Container>
      <Grid container spacing={3}>
        {serviceList?.map((item, idx) => {
          const Icon = appIcons[item.icon];

          return (
            <Grid item lg={3} md={6} sm={6} xs={12} key={idx}>
              <BazarCard
                style={{
                  backgroundColor: 'white'
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: '',
                  justifyContent: '',
                  p: '2rem',
                  paddingRight: '0.5em',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'
                }}
                hoverEffect>
                <Icon
                  style={{
                    color: 'white',
                    backgroundColor: 'lightblue',
                    borderRadius: '50px',
                    marginRight: 15,
                    padding: 10,
                    fontSize: '2rem'
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: ''
                    // p: '2rem'
                  }}>
                  <Typography style={{ fontWeight: '500' }}>{item.title}</Typography>
                  <Typography style={{ color: 'grey' }}>{item.subtitle}</Typography>
                </div>
              </BazarCard>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default HomeSectionPromo;

import Image from './BazarImage';
import FlexBox from './FlexBox';
import { Span } from './Typography';
import React from 'react';
import ProductCard8Style from './ProductCard8Style';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { languages, currency } from 'constants';

const ProductCard8 = ({ item }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <ProductCard8Style style={{ display: 'flex', justifyContent: 'center' }}>
      {!isMobile && (
        <Image
          src={item.imgUrl || '/assets/images/products/iphone-xi.png'}
          width={140}
          display="block"
          alt={item.name}
        />
      )}
      {isMobile && (
        <div>
          <Image
            src={item.imgUrl || '/assets/images/products/iphone-xi.png'}
            // height={140}
            style={{ maxWidth: '150px' }}
            alt={item.name}
          />
        </div>
      )}

      <FlexBox
        className="product-details"
        flexDirection="column"
        justifyContent="space-between"
        padding="10px"
        margin="12px 0px"
        minWidth="0px"
        width="100%">
        <Span
          fontWeight="600"
          fontSize="15px"
          mb={0}
          mr={1}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3
          }}>
          {item.name}
        </Span>

        {item.attributes &&
          Object.keys(item.attributes).map(function (attribute, index) {
            return (
              <Span
                color="grey.600"
                fontSize="14px"
                padding="5px 0px"
                key={item.attributes[attribute].id}>
                {item.attributes[attribute].label} : {item.attributes[attribute].value} cm
              </Span>
            );
          })}

        <FlexBox width="100%" justifyContent="space-between" alignItems="flex-end">
          <FlexBox flexWrap="wrap" alignItems="center">
            <Span color="grey.600" mr={1}>
              {item.price} {currency.BGN} x {item.quantity}
            </Span>
            <Span fontWeight={600} color="primary.main" mr={2}>
              {(item.price * item.quantity).toFixed(2)} {currency.BGN}
            </Span>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ProductCard8Style>
  );
};

export default ProductCard8;

import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import ProductCard7 from './ProductCard7';
import { Span } from './Typography';
import FlexBox from './FlexBox';
import { Link } from 'react-router-dom';
import RadioButtonsGroup from './RadioButtonsGroup';
import { Button, Card, Divider, Typography } from '@mui/material';
import { GlobalContext } from '../context/GlobalState';
import { useContext } from 'react';
import { strings } from 'localisation';
import { routerUrls } from 'constants';
import createGAevent from 'helpers';
import { Helmet } from 'react-helmet';

export default function Cart() {
  const { cartList, shippingOption, deliveryOptions, lang } = useContext(GlobalContext);

  const getTotalPrice = () => {
    return (
      cartList.reduce(
        (accumulator, item) => accumulator + parseFloat(item.price) * item.quantity,
        0
      ) || 0
    );
  };

  const getSubTotalPricePresentation = () => {
    return getTotalPrice().toFixed(2) + ' BGN';
  };

  const getTotalPricePresentation = () => {
    let price = getTotalPrice() + parseFloat(shippingOption.price);
    return price.toFixed(2) + ' BGN';
  };

  const checkoutNow = () => {
    createGAevent('Cart', 'CHECKOUT_NOW_EVENT', 'total-' + getTotalPrice());
    createGAevent('Cart', `CHECKOUT_NOW_total-${getTotalPrice()}`, 'total-' + getTotalPrice());
  };

  return (
    <>
      <Helmet>
        <title>Кошница</title>
        <meta name="description" content="кошница за поръчка" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container style={{ backgroundColor: '', paddingTop: 10, justify: 'center', padding: 10 }}>
        <Typography fontSize="22px" fontWeight="500" color="black" paddingBottom="10px">
          Количка:
        </Typography>
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} xs={12}>
            {cartList.map((item) => (
              <ProductCard7 key={item.id} item={item} />
            ))}
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Card
              sx={{
                padding: '1.5rem 1.75rem',
                '@media only screen and (max-width: 678px)': {
                  padding: '1rem'
                }
              }}>
              <FlexBox justifyContent="space-between" alignItems="center" mb={2}>
                <Span color="grey.600">{strings[lang].subtotal}</Span>
                <FlexBox alignItems="flex-end">
                  <Span fontSize="18px" fontWeight="600" lineheight="1">
                    {getSubTotalPricePresentation()}
                  </Span>
                </FlexBox>
              </FlexBox>
              <Divider
                sx={{
                  mb: '1rem'
                }}
              />
              {/* <TextField label="Voucher" placeholder="Voucher" size="small" variant="outlined" fullWidth />
            <Button variant="outlined" color="primary" fullWidth sx={{}}>Apply Voucher</Button> */}
              <Span fontWeight="600" mb={2} display="block">
                {strings[lang].shipping}
              </Span>
              <RadioButtonsGroup values={deliveryOptions} />
              <FlexBox justifyContent="space-between" alignItems="center" mb={2}>
                <Span fontSize="18px" fontWeight="600" color="black">
                  {strings[lang].total}
                </Span>
                <FlexBox alignItems="flex-end">
                  <Span fontSize="18px" fontWeight="600" lineheight="1">
                    {getTotalPricePresentation()}
                  </Span>
                </FlexBox>
              </FlexBox>

              <Link to={routerUrls.checkout}>
                <Button variant="contained" color="primary" fullWidth onClick={checkoutNow}>
                  {strings[lang].checkoutNow}
                </Button>
              </Link>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

import React from 'react';
import { ContainerPage } from 'components/Typography';
import { Typography } from '@mui/material';
import FlexBox from 'components/FlexBox';

export default function SubmittedForm() {
  return (
    <ContainerPage>
      <FlexBox alignItems="center" flex="1" justifyContent="center" flexDirection="column">
        <Typography fontSize="20px">БЛАГОДАРИМ ВИ!</Typography>
        <Typography fontSize="20px">Ще се свържем с Вас в следващите 24 часа.</Typography>
        <Typography fontSize="16px">
          Ако имате допълнителни въпроси, моля не се колебайте да се свържете с нас и ние с радост
          ще Ви помогнем на info@gotobed.eu или на Viber/WhatsApp +359888936738.
        </Typography>
      </FlexBox>
    </ContainerPage>
  );
}

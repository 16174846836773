import { Card, Divider, Typography } from '@mui/material';
import React from 'react';
import AccordionMenu from './AccordionMenu';
import { GlobalContext } from '../context/GlobalState';
import { useContext, useEffect } from 'react';
import { strings } from 'localisation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

// GOTOBED PRODUCT FILTERS BAR
export default function ProductFilters() {
  const theme = useTheme();
  const { lang } = useContext(GlobalContext);
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { menu, setMenu } = useContext(GlobalContext);
  useEffect(async () => {
    if (!menu) {
      const { data } = await axios.get(`/menu`);
      setMenu(data);
    }
  }, []);

  return (
    <>
      {matches && (
        <Card sx={{ padding: '20px', backgroundColor: '' }} style={{ backgroundColor: '' }}>
          <Typography
            sx={{
              cursor: 'pointer',
              pt: 1,
              fontSize: 18
            }}>
            {strings[lang].productCategories}
          </Typography>
          <Divider sx={{ mt: '18px', mb: '24px' }} />
          {menu && <AccordionMenu categroyList={menu} />}
        </Card>
      )}
    </>
  );
}

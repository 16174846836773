import FlexBox from './FlexBox';
import { Box, Divider, Typography, Grid } from '@mui/material';
import React from 'react';
import { strings } from 'localisation';
import { GlobalContext } from 'context/GlobalState';
import { useContext } from 'react';
import PaymentRadioButtons from './PaymentRadioButtons';
import RadioButtonsGroup from './RadioButtonsGroup';
import ProductCard8 from './ProductCard8';
import { currency } from 'constants';
import { routerUrls } from 'constants';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToTop from './ScrollToTop'; //TODO: FIX PATH

const CheckoutSummary2 = ({
  paymentOptionSelected,
  paymentOptions,
  setPaymentOptionSelected,
  subtotalOrderPrice,
  totalOrderPrice
}) => {
  const { cartList, shippingOption, lang, deliveryOptions } = useContext(GlobalContext);

  const getSubTotalPricePresentation = () => {
    return subtotalOrderPrice.toFixed(2) + currency.BGN;
  };

  const getTotalPricePresentation = () => {
    return totalOrderPrice.toFixed(2) + currency.BGN;
  };

  return (
    <ScrollToTop>
      <Box>
        <Helmet>
          <title>Данни за Поръчка</title>
          <meta name="description" content="направете поръчка" />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Typography color="secondary.900" fontWeight="700" mb={3}>
          {strings[lang].yourOrderDetails}
        </Typography>

        <Grid item>
          {cartList.map((item) => (
            <Link
              key={item.id}
              to={routerUrls.product + '/' + item.slug_bg + '/' + item.id} //here we do the connection with the ProductView
              style={{ color: 'inherit', textDecoration: 'none' }}>
              <ProductCard8 key={item.id} item={item} />
            </Link>
          ))}
        </Grid>

        <Divider
          sx={{
            borderColor: 'grey.300',
            mb: '1.5rem'
          }}
        />

        <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="grey.600">{strings[lang].subtotal}:</Typography>
          <Typography fontWeight="700">{getSubTotalPricePresentation()}</Typography>
        </FlexBox>

        <FlexBox justifyContent="space-between" alignItems="center" mb={1}>
          <Typography color="grey.600">{strings[lang].shipping}:</Typography>
          <Typography fontWeight="700">
            {shippingOption.price} {currency.BGN}
          </Typography>
        </FlexBox>
        <RadioButtonsGroup values={deliveryOptions} />

        {/* <FlexBox justifyContent="space-between" alignItems="center" mb={3}>
        <Typography color="grey.600">Discount:</Typography>
        <Typography fontWeight="700">-</Typography>
      </FlexBox> */}

        <FlexBox fontWeight="700" justifyContent="space-between" alignItems="center" mb={1}>
          <Typography fontWeight="700">{strings[lang].total}:</Typography>
          <Typography fontWeight="700">{getTotalPricePresentation()}</Typography>
        </FlexBox>

        <Divider
          sx={{
            borderColor: 'grey.300',
            mb: '0.5rem'
          }}
        />

        <Typography color="grey.600">{strings[lang].paymentMethodLabel}:</Typography>
        <PaymentRadioButtons
          resultSelected={paymentOptionSelected}
          setResultSelected={setPaymentOptionSelected}
          paymentOptions={paymentOptions}
          key={paymentOptions}
        />
      </Box>
    </ScrollToTop>
  );
};
export default CheckoutSummary2;

import React from 'react';
import Typography from '@mui/material/Typography';
import Accordion from './Accordion';
import AccordionHeader from './AccordionHeader';
import { routerUrls } from 'constants';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// GOTOBED PRODUCT CATEGORIES FILTER ACCORDION
export default function AccordionMenu({ categroyList }) {
  return (
    <div style={{ paddingTop: 10, backgroundColor: '' }}>
      {categroyList?.map((category) => (
        <Accordion key={category.name_bg} expanded>
          <AccordionHeader px={0} py={0.75} color="grey.600">
            <Typography
              sx={{
                cursor: 'pointer',
                mr: '9px'
              }}>
              {category.name_bg}
            </Typography>
          </AccordionHeader>
          {category?.subCategories?.map((subCategory, idx) => (
            <Link
              style={{ color: 'inherit', textDecoration: 'none' }}
              to={
                routerUrls.productsCategories +
                '/' +
                category.slug_bg +
                '/' +
                subCategory.slug_bg +
                '/' +
                category.id +
                '/' +
                subCategory.id
              }
              key={subCategory.id}>
              <Typography
                key={idx}
                fontSize="14px"
                color="grey.600"
                pl="22px"
                py={0.75}
                textDecoration="none"
                sx={{
                  cursor: 'pointer'
                }}>
                {subCategory.name_bg}
              </Typography>
            </Link>
          ))}
        </Accordion>
      ))}
    </div>
  );
}

AccordionMenu.propTypes = {
  categroyList: PropTypes.array
};

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FlexBox from './FlexBox';
import { Span } from './Typography';
import ToastNotification from './ToastNotification';
import 'pure-react-carousel/dist/react-carousel.es.css';
import IncDecButton from './IncDecButton';
import Dropdown from './Dropdown';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';
import { languages, currency } from 'constants';
import { strings } from 'localisation';
import createGAevent from 'helpers';
import fs from 'fs';

export default function ProductViewGridItemAction({
  product,
  additionalProductData,
  variationPrice,
  setVariationPrice
}) {
  const params = useParams();
  const { addCartItem, lang } = useContext(GlobalContext);

  const [isATCtoastOpen, setATCtoastOpen] = React.useState(false);
  const [isAlertOpenNoOptions, setIsAlertOpenNoOptions] = React.useState(false);
  const [isAlertOpenOperation, setIsAlertOpenOperation] = React.useState(false);
  const [attributesArray, setAttributesArray] = React.useState({});
  const [quantity, setQuantity] = React.useState(1);
  const [variationPriceId, setVariationPriceId] = useState(null);

  const notificationATCMessage = strings[lang].nitificationForAddedToCart;
  const buttonLabelATC = strings[lang].addToCartButton;

  let attributesCount = 0;

  if (additionalProductData.heightAttributes && additionalProductData.heightAttributes.length > 0) {
    attributesCount += 1;
  }
  if (additionalProductData.widthAttributes && additionalProductData.widthAttributes.length > 0) {
    attributesCount += 1;
  }
  if (additionalProductData.lengthAttributes && additionalProductData.lengthAttributes.length > 0) {
    attributesCount += 1;
  }
  if (additionalProductData.sizeAttributes && additionalProductData.sizeAttributes.length > 0) {
    attributesCount += 1;
  }

  const addItemToCart = () => {
    let cartItemObj = {};
    cartItemObj['id'] = additionalProductData.id;
    cartItemObj['quantity'] = quantity;
    cartItemObj['price'] = variationPrice;
    cartItemObj['priceId'] = variationPriceId;
    cartItemObj['imgUrl'] = product.images[0].url;
    cartItemObj['attributes'] = attributesArray;
    cartItemObj['slug_bg'] = product.slug_bg;

    if (languages.bg === lang) {
      cartItemObj['name'] = product.name_bg;
    }

    let cartItem = [];
    cartItem.push(cartItemObj);

    if (attributesCount === Object.keys(attributesArray).length && variationPrice != null) {
      addCartItem(cartItem);
      handleOpenNotification();
      createGAevent(
        'Product',
        'ADD_PRODUCT_TO_CART_EVENT',
        'productId-' +
          additionalProductData.id +
          '---priceId-' +
          variationPriceId +
          '---qty-' +
          quantity
      );

      createGAevent(
        'Product',
        `ADD_PRODUCT_TO_CART-productId-${additionalProductData.id}-priceId-${variationPriceId}-qty-${quantity}`,
        'productId-' +
          additionalProductData.id +
          '---priceId-' +
          variationPriceId +
          '---qty-' +
          quantity
      );
    } else if (attributesCount === Object.keys(attributesArray).length && variationPrice == null) {
      setIsAlertOpenOperation(true);
    } else {
      setIsAlertOpenNoOptions(true);
    }
  };

  const handleOpenNotification = () => {
    setATCtoastOpen(true);
  };
  const handleCloseNotification = () => {
    setATCtoastOpen(false);
  };

  const handleCloseAlertNoOptions = () => {
    setIsAlertOpenNoOptions(false);
  };

  const handleCloseAlertOperation = () => {
    setIsAlertOpenOperation(false);
  };

  React.useEffect(() => {
    if (attributesCount === Object.keys(attributesArray).length && attributesCount > 0) {
      getVariationPrice(attributesArray, additionalProductData.id);
    }
  }, [attributesArray]);

  const getVariationPrice = async (attributes, productId) => {
    if (productId != null) {
      params['productId'] = productId;
    }
    if ('width' in attributes) {
      params['widthAttributeId'] = attributes.width.id;
    }
    if ('length' in attributes) {
      params['lengthAttributeId'] = attributes.length.id;
    }
    if ('height' in attributes) {
      params['heightAttributeId'] = attributes.height.id;
    }
    if ('size' in attributes) {
      params['sizeAttributeId'] = attributes.size.id;
    }
    const { data: response } = await axios
      .get(`/price/?`, {
        params
      })
      .catch((error) => {
        let errorMessage = {
          error:
            '--------------------------------------------------------------------------' +
            new Date() +
            '-  Error while trying to getVariationPrice!!!',
          error_log: error
        };
        fs.appendFile(
          'error_logs.json',
          JSON.stringify(errorMessage) + '---------------------------------------------------------'
        );
      });
    if (response.length > 0 && response[0].price_bgn) {
      setVariationPrice(response[0].price_bgn);
      setVariationPriceId(response[0].id);
    } else if (response.length === 0) {
      setVariationPrice(null);
    }
  };

  const attributeLabelLocalised = {
    length: {
      bg: 'дължина на матрака (см)',
      en: 'lmattress length'
    },
    width: {
      bg: 'ширина на матрака (см)',
      en: 'mattress width'
    },
    length_duvet_cover: {
      bg: 'дължина (см)',
      en: 'length'
    },
    width_duvet_cover: {
      bg: 'ширина (см)',
      en: 'width'
    },
    height: {
      bg: 'височина на матрака (см)',
      en: 'mattress height'
    },
    size_pillowcase: {
      bg: 'размер (см)',
      en: 'size'
    },
    size: {
      bg: 'размер (см) - ширина x дължина ',
      en: 'size - width x length'
    },
    size_kids_fitted: {
      bg: 'размер (см) - Ш х Д х В',
      en: 'size'
    },
    length_round: {
      bg: 'диаметър на матрака (см)',
      en: 'mattress diameter'
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={3}
      style={{
        backgroundColor: ''
        // display:'flex',
        // flexDirection:'column',
      }}>
      <ToastNotification
        open={isAlertOpenNoOptions}
        handleCloseNotification={handleCloseAlertNoOptions}
        type="alert"
        position="top-center"
        bckgrColor="red"
        textColor="white"
        message="Моля, първо изберете опции на продукта преди да го добавите в количката."
      />

      <ToastNotification
        open={isAlertOpenOperation}
        handleCloseNotification={handleCloseAlertOperation}
        type="alert"
        position="top-center"
        bckgrColor="red"
        textColor="white"
        message="За съжаление тази операция не може да бъде изпълнена. Моля, опитайте отното като изберете други параметри за продукта."
      />

      <ToastNotification
        open={isATCtoastOpen}
        handleCloseNotification={handleCloseNotification}
        time="2000"
        message={notificationATCMessage}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}>
        {additionalProductData.widthAttributes &&
          additionalProductData.widthAttributes.length > 0 &&
          product.subCategoryId !== 4 && (
            <Dropdown
              attributeType="width"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['width'].bg
                  : attributeLabelLocalised['width'].en
              }
              values={additionalProductData.widthAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.widthAttributes &&
          additionalProductData.widthAttributes.length > 0 &&
          product.subCategoryId == 4 && (
            <Dropdown
              attributeType="width"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['width_duvet_cover'].bg
                  : attributeLabelLocalised['width_duvet_cover'].en
              }
              values={additionalProductData.widthAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.lengthAttributes &&
          additionalProductData.lengthAttributes.length > 0 &&
          product.subCategoryId !== 6 &&
          product.subCategoryId == 4 && (
            <Dropdown
              attributeType="length"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['length_duvet_cover'].bg
                  : attributeLabelLocalised['length_duvet_cover'].en
              }
              values={additionalProductData.lengthAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.lengthAttributes &&
          additionalProductData.lengthAttributes.length > 0 &&
          product.subCategoryId !== 6 &&
          product.subCategoryId !== 4 && (
            <Dropdown
              attributeType="length"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['length'].bg
                  : attributeLabelLocalised['length'].en
              }
              values={additionalProductData.lengthAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.lengthAttributes &&
          additionalProductData.lengthAttributes.length > 0 &&
          product.subCategoryId === 6 &&
          product.subCategoryId !== 4 && (
            <Dropdown
              attributeType="length"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['length_round'].bg
                  : attributeLabelLocalised['length_round'].en
              }
              values={additionalProductData.lengthAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.heightAttributes &&
          additionalProductData.heightAttributes.length > 0 && (
            <Dropdown
              attributeType="height"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['height'].bg
                  : attributeLabelLocalised['height'].en
              }
              values={additionalProductData.heightAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.sizeAttributes &&
          additionalProductData.sizeAttributes.length > 0 &&
          product.subCategoryId !== 2 &&
          product.subCategoryId !== 8 &&
          product.subCategoryId !== 9 && (
            <Dropdown
              attributeType="size"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['size'].bg
                  : attributeLabelLocalised['size'].en
              }
              values={additionalProductData.sizeAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.sizeAttributes &&
          additionalProductData.sizeAttributes.length > 0 &&
          product.subCategoryId == 9 && (
            <Dropdown
              attributeType="size"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['size_kids_fitted'].bg
                  : attributeLabelLocalised['size_kids_fitted'].en
              }
              values={additionalProductData.sizeAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        {additionalProductData.sizeAttributes &&
          additionalProductData.sizeAttributes.length > 0 &&
          (product.subCategoryId == 2 || product.subCategoryId == 8) && (
            <Dropdown
              attributeType="size"
              attributeLabel={
                languages.bg === lang
                  ? attributeLabelLocalised['size_pillowcase'].bg
                  : attributeLabelLocalised['size_pillowcase'].en
              }
              values={additionalProductData.sizeAttributes}
              attributesArray={attributesArray}
              setAttributesArrayFunc={setAttributesArray}
            />
          )}

        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
          }}>
          {attributesCount === Object.keys(attributesArray).length && variationPrice != null && (
            <Typography
              sx={{ p: 1, paddingTop: 2, fontSize: '20px', fontWeight: '700', color: 'green' }}>
              {variationPrice} {currency.BGN}
            </Typography>
          )}

          {quantity > 1 && variationPrice > 0 && (
            <FlexBox
              flexWrap="wrap"
              alignItems="center"
              style={{ paddingTop: 5, paddingBottom: 10 }}>
              <Span color="grey.600" mr={1}>
                {variationPrice} {currency.BGN} x {quantity} =
              </Span>
              <Span fontWeight={600} color="#39973c" mr={2}>
                {(variationPrice * quantity).toFixed(2)} {currency.BGN}
              </Span>
            </FlexBox>
          )}

          {attributesCount > 0 && <IncDecButton counter={quantity} setCounter={setQuantity} />}

          {attributesCount > 0 && (
            <Button
              variant="contained"
              color="success"
              sx={{ mb: 2, mt: 2 }}
              onClick={addItemToCart}>
              {buttonLabelATC}
            </Button>
          )}

          {/* <ModalPopup url={product.sizing_guide} /> */}
        </Box>
      </Box>
    </Grid>
  );
}

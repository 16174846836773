import React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useState, useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';

export default function Dropdown({
  attributeType,
  attributeLabel,
  values,
  attributesArray,
  setAttributesArrayFunc
}) {
  const [attribute, setAttribute] = useState('');
  const { lang } = useContext(GlobalContext);

  values.sort((a, b) => a.position - b.position);

  const handleAttributeChange = (event) => {
    const selectedAttributeValue = event.target.value;
    setAttribute(selectedAttributeValue);

    var selectedAttribute = values.filter(function (val) {
      return val.value === selectedAttributeValue;
    });

    selectedAttribute[0]['label'] = attributeLabel;
    attributesArray[attributeType] = selectedAttribute[0];
    setAttributesArrayFunc({ ...attributesArray });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ p: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{attributeLabel}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={attribute}
            label={attributeLabel}
            onChange={handleAttributeChange}>
            {values.map((attr) => (
              <MenuItem key={attr.value} value={attr.value}>
                {attr.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

import React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Product from './Product';
import ProductFilters from './ProductFilters';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalState';
import { strings } from 'localisation';
import { Typography } from '@mui/material';
import { themeColors } from 'constants';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import fs from 'fs';

// GOTOBED ALL PRODUCTS COMPONENTS
export default function ProductsSearch() {
  const { searchList, loadSearchList, clearSearchList, termParam, setTermParam } =
    useContext(GlobalContext);
  const { lang } = useContext(GlobalContext);

  const params = useParams();
  const [noResults, setNoResults] = React.useState(false);

  // WHRE WE SET THE CATEGORY AND SUBCATEGORY ATTRIBUTES HERE???
  const fetchProductSearchData = async () => {
    try {
      const { data: response } = await axios.get(`/search/?lang=bg`, {
        params
      });
      response.forEach((product) => {
        product['images'].sort(function (a, b) {
          return a['Product_Image'].position - b['Product_Image'].position;
        });
      });
      return response;
    } catch (error) {
      if (error.response) {
        // Not in the 200 response range
        let errorMessage = {
          error:
            '--------------------------------------------------------------------------' +
            new Date() +
            '-  Error while trying to fetchProductSearchData!!!',
          error_log: error
        };
        fs.appendFile(
          'error_logs.json',
          JSON.stringify(errorMessage) +
            '--------------------------------------------------------------------------------------------------------------'
        );
        return 'error';
      } else {
        return 'error';
      }
    }
  };

  const fetchData = async () => {
    const data = await fetchProductSearchData();
    // maybe there is a proper way to do this
    if (data != 'error') {
      await loadSearchList(data);
      if (data === [] || data.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  };

  const clearData = async () => {
    await clearSearchList();
  };

  useEffect(() => {
    if (termParam !== null || termParam !== '' || params?.term !== termParam) {
      clearData();
      setTermParam(params?.term);
    }
    fetchData();
  }, [params?.term]);

  const persistScrollPosition = (id) => {
    sessionStorage.setItem('scroll-position-product-id-marker', id);
  };

  return (
    <Container style={{ backgroundColor: '', paddingTop: 20, justify: 'center' }}>
      <Grid container justify="center" spacing={3} style={{ backgroundColor: '' }}>
        <Grid item lg={3} xs={12}>
          <ProductFilters />
        </Grid>

        <Grid item style={{ backgroundColor: '' }} lg={9} xs={12}>
          {noResults && (
            <div style={{ padding: 10 }}>
              <ManageSearchIcon fontSize="large" />
              <Typography style={{ display: 'flex' }}>
                {/* FIX TODO: is &nbsp; a good idea ??? */}
                {strings[lang].noSearchResults1} &nbsp;
                <Typography
                  sx={{
                    color: themeColors.cherryRedDeep,
                    fontStyle: 'italic'
                  }}>
                  {termParam}
                </Typography>
              </Typography>
              <Typography>{strings[lang].noSearchResults2}</Typography>
            </div>
          )}
          <Grid container spacing={3}>
            {searchList &&
              searchList?.map((ProductItem) => (
                <Grid item key={ProductItem.id} lg={3} xs={6}>
                  <Product Product={ProductItem} onSelect={persistScrollPosition} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

import Carousel from './Carousel';
import { Box, Container } from '@mui/material';
import LazyImage from './LazyImage';
import { Fragment } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CarouselSection = ({ carouselData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Fragment>
      <Box bgcolor="">
        <Container maxWidth={false} style={{ backgroundColor: '' }}>
          <Carousel
            totalSlides={3}
            visibleSlides={1}
            infinite={true}
            autoPlay={true}
            showDots={true}
            showArrow={false}
            spacing="0px">
            {carouselData &&
              carouselData.map((data, ind) => (
                <div
                  key={ind}
                  style={{
                    position: 'relative',
                    minHeight: isMobile ? '150px' : '600px',
                    minWidth: isMobile ? '150px' : '600px',
                    maxHeight: isMobile ? '150px' : '600px',
                    backgroundColor: 'white'
                  }}>
                  <LazyImage
                    src={data.img_url}
                    alt={`Image ${ind + 1}`}
                    width="100%"
                    height="auto"
                    style={{ objectFit: 'cover' }}
                    loading="lazy"
                  />
                </div>
              ))}
          </Carousel>
        </Container>
      </Box>
    </Fragment>
  );
};

export default CarouselSection;

import BazarAvatar from './BazarAvatar';
import BazarButton from './BazarButton';
import BazarIconButton from './BazarIconButton';
import FlexBox from './FlexBox';
import { H5, Tiny } from './Typography';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';
import Remove from '@mui/icons-material/Remove';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback } from 'react';
import shoppingBag from 'icons/shopping-bag.svg';
import { GlobalContext } from '../context/GlobalState';
import { useContext } from 'react';
import { currency, routerUrls } from 'constants';
import { useMediaQuery } from '@mui/material';
import { strings } from 'localisation';
import Image from './BazarImage';

const MiniCart = ({ toggleSidenav }) => {
  const { palette } = useTheme();
  const theme = useTheme();
  const { cartList, changeCartItemQty } = useContext(GlobalContext);
  const { lang } = useContext(GlobalContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleCartAmountChange = useCallback(
    (amount, product) => () => {
      return changeCartItemQty({ ...product, quantity: amount });
    },
    []
  );
  const getTotalPrice = () => {
    return cartList.reduce((accumulator, item) => accumulator + item.price * item.quantity, 0) || 0;
  };

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          width: '70vw'
        },
        [theme.breakpoints.up('md')]: {
          width: '380px'
        }
      })}>
      <Box overflow="auto" height={`calc(100vh - ${cartList.length ? '80px - 3.25rem' : '0px'})`}>
        <FlexBox alignItems="center" m="0px 20px" height="74px" color="secondary.main">
          <ShoppingCartIcon color="inherit" sx={{ p: 0, color: 'grey.400' }} />
          <Box fontWeight={600} fontSize="16px" ml={1} color="grey.600">
            {cartList.length == 1 && (
              <Typography style={{ color: 'grey' }}>
                {cartList.length} {strings[lang].product}
              </Typography>
            )}
            {cartList.length != 1 && (
              <Typography style={{ color: 'grey' }}>
                {cartList.length} {strings[lang].products}
              </Typography>
            )}
          </Box>
        </FlexBox>

        <Divider />

        {/* if cart is empty */}
        {!cartList.length && (
          <FlexBox
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="calc(100% - 74px)">
            {/* used to be LazyImage not img    */}
            <img src={shoppingBag} width={90} height={100} />
            <Box component="p" mt={2} color="grey.600" textAlign="center" maxWidth="200px">
              {strings[lang].emptyCartMessage}
            </Box>
          </FlexBox>
        )}

        {isMobile &&
          cartList.map((item) => (
            <div key={item.id} style={{ wordWrap: 'break-word', padding: '10px' }}>
              <div
                style={{
                  backgroundColor: '',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <FlexBox alignItems="center">
                  <FlexBox alignItems="center" flexDirection="column">
                    <BazarButton
                      variant="outlined"
                      color="primary"
                      sx={{
                        height: '32px',
                        width: '32px',
                        borderRadius: '300px'
                      }}
                      onClick={handleCartAmountChange(item.quantity + 1, item)}>
                      <Add fontSize="small" />
                    </BazarButton>

                    <Box fontWeight={600} fontSize="15px" my="3px">
                      {item.quantity}
                    </Box>

                    <BazarButton
                      variant="outlined"
                      color="primary"
                      sx={{
                        height: '32px',
                        width: '32px',
                        borderRadius: '300px'
                      }}
                      onClick={handleCartAmountChange(item.quantity - 1, item)}
                      disabled={item.quantity === 1}>
                      <Remove fontSize="small" />
                    </BazarButton>
                  </FlexBox>

                  {/* item image */}
                  {/* TODO NOT WORKING AS EXPECTED
            <Link
              key={item.id}
              to={routerUrls.product + '/' + item.slug_bg + '/' + item.id} //here we do the connection with the ProductView
              style={{ color: 'inherit', textDecoration: 'none' }}> */}
                  <Image
                    src={item.imgUrl || 'defaul_image'} //TODO: add default image here
                    mx={2}
                    alt={item.name}
                    width={76}
                  />
                  {/* </Link> */}
                  <BazarIconButton size="small" onClick={handleCartAmountChange(0, item)}>
                    <Close fontSize="small" />
                  </BazarIconButton>
                </FlexBox>
              </div>
              <div
                style={{
                  backgroundColor: '',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Box
                  style={{
                    backgroundColor: '',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '5px'
                  }}>
                  <H5
                    className="title"
                    fontSize="14px"
                    align="center"
                    style={{ paddingLeft: 2, paddingRight: 2 }}
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3
                    }}>
                    {item.name}
                  </H5>

                  {item.attributes &&
                    Object.keys(item.attributes).map(function (attribute, index) {
                      return (
                        <Box
                          color="grey.500"
                          fontSize="14px"
                          key={item.attributes[attribute].id}
                          paddingTop="6px">
                          {item.attributes[attribute].label} : {item.attributes[attribute].value}
                        </Box>
                      );
                    })}

                  {/* item price * item quantity */}
                  <Tiny color="grey.700" fontSize="13px" mt={0.9}>
                    {item.price} {currency.BGN} x {item.quantity}
                  </Tiny>

                  {/* result of = item price * item quantity */}
                  <Box fontWeight={600} fontSize="14px" color="primary.main" paddingBottom="10px">
                    {(item.quantity * item.price).toFixed(2)} {currency.BGN}
                  </Box>
                </Box>
              </div>
              <Divider />
            </div>
          ))}

        {!isMobile &&
          cartList.map((item) => (
            <FlexBox
              alignItems="center"
              display="flex"
              flex-direction="column"
              py={2}
              px={2.5}
              borderBottom={`1px solid ${palette.divider}`}
              key={item.id}>
              <FlexBox alignItems="center">
                <FlexBox alignItems="center" flexDirection="column">
                  <BazarButton
                    variant="outlined"
                    color="primary"
                    sx={{
                      height: '32px',
                      width: '32px',
                      borderRadius: '300px'
                    }}
                    onClick={handleCartAmountChange(item.quantity + 1, item)}>
                    <Add fontSize="small" />
                  </BazarButton>

                  <Box fontWeight={600} fontSize="15px" my="3px">
                    {item.quantity}
                  </Box>

                  <BazarButton
                    variant="outlined"
                    color="primary"
                    sx={{
                      height: '32px',
                      width: '32px',
                      borderRadius: '300px'
                    }}
                    onClick={handleCartAmountChange(item.quantity - 1, item)}
                    disabled={item.quantity === 1}>
                    <Remove fontSize="small" />
                  </BazarButton>
                </FlexBox>
                <BazarAvatar src={item.imgUrl} mx={2} alt={item.name} height={76} width={76} />
              </FlexBox>

              {/* item name */}
              <Box flex-direction="column">
                <H5 className="title" fontSize="14px">
                  {item.name}
                </H5>
                {/* </Link>  */}

                {item.attributes &&
                  Object.keys(item.attributes).map(function (attribute, index) {
                    return (
                      <Box color="grey.500" fontSize="11px" key={item.attributes[attribute].id}>
                        {item.attributes[attribute].label} : {item.attributes[attribute].value} cm
                      </Box>
                    );
                  })}

                {/* item price * item quantity */}
                <Tiny color="grey.700" fontSize="12px" mt={0.9}>
                  {item.price} {currency.BGN} x {item.quantity}
                </Tiny>

                {/* result of = item price * item quantity */}
                <Box fontWeight={600} fontSize="14px" color="primary.main">
                  {(item.quantity * item.price).toFixed(2)} {currency.BGN}
                </Box>
              </Box>

              <BazarIconButton ml={2.5} size="small" onClick={handleCartAmountChange(0, item)}>
                <Close fontSize="small" />
              </BazarIconButton>
            </FlexBox>
          ))}
      </Box>

      {!!cartList.length && (
        <Box p={2.5}>
          <Link to={routerUrls.cart} style={{ color: 'inherit', textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mb: '0.75rem',
                height: '50px'
              }}
              fullWidth
              onClick={toggleSidenav}>
              {strings[lang].subtotal}: <br /> {getTotalPrice().toFixed(2)} {currency.BGN}
            </Button>
          </Link>

          <Link to={routerUrls.cart} style={{ color: 'inherit', textDecoration: 'none' }}>
            <BazarButton
              color="primary"
              variant="outlined"
              sx={{
                height: 40
              }}
              fullWidth
              onClick={toggleSidenav}>
              {strings[lang].viewCart}
            </BazarButton>
          </Link>
        </Box>
      )}
    </Box>
  );
};

MiniCart.defaultProps = {
  toggleSidenav: () => {}
};
export default MiniCart;
